import cookieparser from 'cookieparser'
// import config from '../config.js'
// import axios from '../plugins/axios.js'
// const { cookiePrependKey } = config

export const actions = {
  async nuxtServerInit({ commit }, { req, res, $axios, app, redirect, route }) {
    // helper function
    const redirectOnError = () => {
      if (app.$routeHelper.isNonAuthedRoute(route)) return true
      return redirect({ path: '/log-in', query: { noAuth: true, redirect: route.path } })
    }
    try {
      // Create and set a new csrf token to help secure the auth endpoints
      const csrfToken = (Math.random() * 100000000000000000).toString()
      // set using setHeader (remember we're server-side here)
      res.setHeader('Set-Cookie', [`csrfToken=${csrfToken};Path=/`])
      // if there is no auth cookie in the request, the user's obvs not authed so redirect them to login if needed
      const cookies = req.headers.cookie && cookieparser.parse(req.headers.cookie)
      if (!cookies || !cookies.CONC_CRISP_auth) return redirectOnError()
      // check to see if the user's auth token is still valid will return the user's custom claims object or false
      const tokenCheckResponse = await $axios.get('/api/auth/check-auth-token')
      const claimsObject = tokenCheckResponse.data
      // if no claimsObject returned, user is not authed so redirect
      if (!claimsObject) return redirectOnError()
      // otherwise set the user claims in the state
      commit('user/claims', claimsObject, { root: true })
      // set the user's uid in the state
      app.$user.setAuthID(claimsObject.uid)
      // if the route is one that should redirect the user 'home', do that
      if (app.$routeHelper.isRedirectHomeRoute(route)) {
        return app.$routeHelper.redirectHome({ claimsObject })
      }
      // if the user has permissions for the route let them have it
      if (app.$routeHelper.userHasPermissionsForRoute(route, claimsObject)) {
        return true
      }
      // otherwise, redirect the user home
      return app.$routeHelper.redirectHome(claimsObject)
    } catch (e) {
      console.error(e)
      if (e.code === 'ECONNREFUSED' && process.env.dev) {
        app.$snack.info('Could not connect to the local api service for auth at :8080')
      }
      return redirectOnError()
    }
  },
  clearStore({ commit }) {
    // List all the mutations to clear store modules needed here.
    // this will be called when auth state changes and user is null
    const mutations = ['user/clear']
    mutations.map((x) => commit(x))
    // const dispatches = ['stats/stopWatchingTimesheetStatuses']
    // return dispatches.map(x => dispatch(x))
  }
}

export const getters = {}
