export default async ({ route, store }) => {
  const { path, fullPath } = route
  store.commit('routes/current', { path, fullPath })
  // try and get the pageTitle meta out of the route and set it in the store.
  // mageTitle should be set on each page in the page's single-file-component.
  const pageTitle = route.meta.reduce((pageTitle, meta) => {
    return meta.pageTitle || pageTitle
  }, '')
  store.commit('routes/pageTitle', pageTitle)
}
