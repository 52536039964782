import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _299c631d = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _4c9a21f4 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _ceb8001a = () => interopDefault(import('../pages/authoriser/index.vue' /* webpackChunkName: "pages/authoriser/index" */))
const _78f163b0 = () => interopDefault(import('../pages/candidate/index.vue' /* webpackChunkName: "pages/candidate/index" */))
const _62b461bc = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _2f61e35c = () => interopDefault(import('../pages/log-in.vue' /* webpackChunkName: "pages/log-in" */))
const _041d1ce7 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _87103758 = () => interopDefault(import('../pages/set-up-account.vue' /* webpackChunkName: "pages/set-up-account" */))
const _1f0c8d0d = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _1f655d3c = () => interopDefault(import('../pages/sign-up-step.vue' /* webpackChunkName: "pages/sign-up-step" */))
const _4d10bdd2 = () => interopDefault(import('../pages/timesheets/index.vue' /* webpackChunkName: "pages/timesheets/index" */))
const _df5daf06 = () => interopDefault(import('../pages/verify-email-address.vue' /* webpackChunkName: "pages/verify-email-address" */))
const _1c1c64d9 = () => interopDefault(import('../pages/admin/consultant-view.vue' /* webpackChunkName: "pages/admin/consultant-view" */))
const _a42c0392 = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _85f2b350 = () => interopDefault(import('../pages/admin/management-dashboard.vue' /* webpackChunkName: "pages/admin/management-dashboard" */))
const _e1bcd402 = () => interopDefault(import('../pages/admin/placements/index.vue' /* webpackChunkName: "pages/admin/placements/index" */))
const _42263e82 = () => interopDefault(import('../pages/admin/released-candidates.vue' /* webpackChunkName: "pages/admin/released-candidates" */))
const _70792ba4 = () => interopDefault(import('../pages/admin/reports/index.vue' /* webpackChunkName: "pages/admin/reports/index" */))
const _69d83723 = () => interopDefault(import('../pages/admin/system-settings.vue' /* webpackChunkName: "pages/admin/system-settings" */))
const _622c17dc = () => interopDefault(import('../pages/admin/timesheets/index.vue' /* webpackChunkName: "pages/admin/timesheets/index" */))
const _27a93b2d = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _6a47d6a6 = () => interopDefault(import('../pages/candidate/my-account.vue' /* webpackChunkName: "pages/candidate/my-account" */))
const _4d625476 = () => interopDefault(import('../pages/candidate/my-placements.vue' /* webpackChunkName: "pages/candidate/my-placements" */))
const _8248daca = () => interopDefault(import('../pages/candidate/my-timesheets/index.vue' /* webpackChunkName: "pages/candidate/my-timesheets/index" */))
const _4a6f5482 = () => interopDefault(import('../pages/company/my-company/index.vue' /* webpackChunkName: "pages/company/my-company/index" */))
const _26c0c413 = () => interopDefault(import('../pages/admin/account/settings.vue' /* webpackChunkName: "pages/admin/account/settings" */))
const _86c5ad38 = () => interopDefault(import('../pages/admin/invoices/reports.vue' /* webpackChunkName: "pages/admin/invoices/reports" */))
const _716b2eab = () => interopDefault(import('../pages/admin/placements/awr-alerts.vue' /* webpackChunkName: "pages/admin/placements/awr-alerts" */))
const _f3cd0b44 = () => interopDefault(import('../pages/admin/placements/by-consultant.vue' /* webpackChunkName: "pages/admin/placements/by-consultant" */))
const _35de2c12 = () => interopDefault(import('../pages/admin/placements/cr.vue' /* webpackChunkName: "pages/admin/placements/cr" */))
const _e7e5ce04 = () => interopDefault(import('../pages/admin/placements/ctb.vue' /* webpackChunkName: "pages/admin/placements/ctb" */))
const _366595b3 = () => interopDefault(import('../pages/admin/placements/inbox.vue' /* webpackChunkName: "pages/admin/placements/inbox" */))
const _073b0c52 = () => interopDefault(import('../pages/admin/placements/my-gp.vue' /* webpackChunkName: "pages/admin/placements/my-gp" */))
const _28d381da = () => interopDefault(import('../pages/admin/placements/queried-timesheets.vue' /* webpackChunkName: "pages/admin/placements/queried-timesheets" */))
const _3a6eb816 = () => interopDefault(import('../pages/admin/placements/test.vue' /* webpackChunkName: "pages/admin/placements/test" */))
const _2494f5c2 = () => interopDefault(import('../pages/admin/placements/working-by-consultant.vue' /* webpackChunkName: "pages/admin/placements/working-by-consultant" */))
const _42dfb48f = () => interopDefault(import('../pages/admin/timesheets/authoriser-requests.vue' /* webpackChunkName: "pages/admin/timesheets/authoriser-requests" */))
const _4905b1de = () => interopDefault(import('../pages/admin/timesheets/authorisers.vue' /* webpackChunkName: "pages/admin/timesheets/authorisers" */))
const _30f83be4 = () => interopDefault(import('../pages/admin/timesheets/authorisers-advanced.vue' /* webpackChunkName: "pages/admin/timesheets/authorisers-advanced" */))
const _c08fd51e = () => interopDefault(import('../pages/admin/timesheets/by-consultant.vue' /* webpackChunkName: "pages/admin/timesheets/by-consultant" */))
const _1bf1bedf = () => interopDefault(import('../pages/admin/timesheets/cr.vue' /* webpackChunkName: "pages/admin/timesheets/cr" */))
const _686bdfd1 = () => interopDefault(import('../pages/admin/timesheets/ctb.vue' /* webpackChunkName: "pages/admin/timesheets/ctb" */))
const _c511d702 = () => interopDefault(import('../pages/admin/timesheets/reports.vue' /* webpackChunkName: "pages/admin/timesheets/reports" */))
const _988854ee = () => interopDefault(import('../pages/admin/tools/archived-candidates.vue' /* webpackChunkName: "pages/admin/tools/archived-candidates" */))
const _5b1afa64 = () => interopDefault(import('../pages/admin/tools/csv-import.vue' /* webpackChunkName: "pages/admin/tools/csv-import" */))
const _40307dee = () => interopDefault(import('../pages/admin/tools/elasticsearch/index.vue' /* webpackChunkName: "pages/admin/tools/elasticsearch/index" */))
const _3459ed4e = () => interopDefault(import('../pages/admin/tools/emails.vue' /* webpackChunkName: "pages/admin/tools/emails" */))
const _077c7930 = () => interopDefault(import('../pages/admin/tools/leaver-candidates.vue' /* webpackChunkName: "pages/admin/tools/leaver-candidates" */))
const _7f3cef96 = () => interopDefault(import('../pages/admin/tools/live-history.vue' /* webpackChunkName: "pages/admin/tools/live-history" */))
const _f0d4a870 = () => interopDefault(import('../pages/admin/tools/timesheets-cron.vue' /* webpackChunkName: "pages/admin/tools/timesheets-cron" */))
const _eed14628 = () => interopDefault(import('../pages/admin/tools/vincere-tools.vue' /* webpackChunkName: "pages/admin/tools/vincere-tools" */))
const _00df6efc = () => interopDefault(import('../pages/admin/users/admins/index.vue' /* webpackChunkName: "pages/admin/users/admins/index" */))
const _40b1fdc8 = () => interopDefault(import('../pages/admin/users/candidates/index.vue' /* webpackChunkName: "pages/admin/users/candidates/index" */))
const _5e64b439 = () => interopDefault(import('../pages/admin/users/companies/index.vue' /* webpackChunkName: "pages/admin/users/companies/index" */))
const _6eb2b78a = () => interopDefault(import('../pages/admin/users/umbrella-companies/index.vue' /* webpackChunkName: "pages/admin/users/umbrella-companies/index" */))
const _5b409398 = () => interopDefault(import('../pages/company/my-company/authorisation-requests.vue' /* webpackChunkName: "pages/company/my-company/authorisation-requests" */))
const _553893c2 = () => interopDefault(import('../pages/admin/users/companies/company-users.vue' /* webpackChunkName: "pages/admin/users/companies/company-users" */))
const _214c181a = () => interopDefault(import('../pages/admin/users/admins/_uid/index.vue' /* webpackChunkName: "pages/admin/users/admins/_uid/index" */))
const _be022e64 = () => interopDefault(import('../pages/admin/users/candidates/_uid/index.vue' /* webpackChunkName: "pages/admin/users/candidates/_uid/index" */))
const _d53cde06 = () => interopDefault(import('../pages/admin/users/companies/_uid/index.vue' /* webpackChunkName: "pages/admin/users/companies/_uid/index" */))
const _3dd73a00 = () => interopDefault(import('../pages/admin/users/company-users/_uid/index.vue' /* webpackChunkName: "pages/admin/users/company-users/_uid/index" */))
const _6088f78e = () => interopDefault(import('../pages/company/my-company/candidate/_candidateID.vue' /* webpackChunkName: "pages/company/my-company/candidate/_candidateID" */))
const _175c164e = () => interopDefault(import('../pages/company/my-company/placement/_placementID.vue' /* webpackChunkName: "pages/company/my-company/placement/_placementID" */))
const _e0ff87a4 = () => interopDefault(import('../pages/company/my-company/timesheet/_timesheetID.vue' /* webpackChunkName: "pages/company/my-company/timesheet/_timesheetID" */))
const _069b25a2 = () => interopDefault(import('../pages/admin/invoices/_id/index.vue' /* webpackChunkName: "pages/admin/invoices/_id/index" */))
const _619ccd2c = () => interopDefault(import('../pages/admin/placements/_id/index.vue' /* webpackChunkName: "pages/admin/placements/_id/index" */))
const _18a6b5fd = () => interopDefault(import('../pages/admin/timesheets/_id/index.vue' /* webpackChunkName: "pages/admin/timesheets/_id/index" */))
const _405f4289 = () => interopDefault(import('../pages/admin/users/_uid/index.vue' /* webpackChunkName: "pages/admin/users/_uid/index" */))
const _a398536a = () => interopDefault(import('../pages/candidate/my-timesheets/_placementID/index.vue' /* webpackChunkName: "pages/candidate/my-timesheets/_placementID/index" */))
const _7b5e74e8 = () => interopDefault(import('../pages/candidate/my-timesheets/_placementID/_timesheetID/index.vue' /* webpackChunkName: "pages/candidate/my-timesheets/_placementID/_timesheetID/index" */))
const _3c3fa92e = () => interopDefault(import('../pages/authorisation-request/_linkID.vue' /* webpackChunkName: "pages/authorisation-request/_linkID" */))
const _2acff034 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _299c631d,
    name: "404"
  }, {
    path: "/admin",
    component: _4c9a21f4,
    name: "admin"
  }, {
    path: "/authoriser",
    component: _ceb8001a,
    name: "authoriser"
  }, {
    path: "/candidate",
    component: _78f163b0,
    name: "candidate"
  }, {
    path: "/company",
    component: _62b461bc,
    name: "company"
  }, {
    path: "/log-in",
    component: _2f61e35c,
    name: "log-in"
  }, {
    path: "/reset-password",
    component: _041d1ce7,
    name: "reset-password"
  }, {
    path: "/set-up-account",
    component: _87103758,
    name: "set-up-account"
  }, {
    path: "/sign-up",
    component: _1f0c8d0d,
    name: "sign-up"
  }, {
    path: "/sign-up-step",
    component: _1f655d3c,
    name: "sign-up-step"
  }, {
    path: "/timesheets",
    component: _4d10bdd2,
    name: "timesheets"
  }, {
    path: "/verify-email-address",
    component: _df5daf06,
    name: "verify-email-address"
  }, {
    path: "/admin/consultant-view",
    component: _1c1c64d9,
    name: "admin-consultant-view"
  }, {
    path: "/admin/invoices",
    component: _a42c0392,
    name: "admin-invoices"
  }, {
    path: "/admin/management-dashboard",
    component: _85f2b350,
    name: "admin-management-dashboard"
  }, {
    path: "/admin/placements",
    component: _e1bcd402,
    name: "admin-placements"
  }, {
    path: "/admin/released-candidates",
    component: _42263e82,
    name: "admin-released-candidates"
  }, {
    path: "/admin/reports",
    component: _70792ba4,
    name: "admin-reports"
  }, {
    path: "/admin/system-settings",
    component: _69d83723,
    name: "admin-system-settings"
  }, {
    path: "/admin/timesheets",
    component: _622c17dc,
    name: "admin-timesheets"
  }, {
    path: "/admin/users",
    component: _27a93b2d,
    name: "admin-users"
  }, {
    path: "/candidate/my-account",
    component: _6a47d6a6,
    name: "candidate-my-account"
  }, {
    path: "/candidate/my-placements",
    component: _4d625476,
    name: "candidate-my-placements"
  }, {
    path: "/candidate/my-timesheets",
    component: _8248daca,
    name: "candidate-my-timesheets"
  }, {
    path: "/company/my-company",
    component: _4a6f5482,
    name: "company-my-company"
  }, {
    path: "/admin/account/settings",
    component: _26c0c413,
    name: "admin-account-settings"
  }, {
    path: "/admin/invoices/reports",
    component: _86c5ad38,
    name: "admin-invoices-reports"
  }, {
    path: "/admin/placements/awr-alerts",
    component: _716b2eab,
    name: "admin-placements-awr-alerts"
  }, {
    path: "/admin/placements/by-consultant",
    component: _f3cd0b44,
    name: "admin-placements-by-consultant"
  }, {
    path: "/admin/placements/cr",
    component: _35de2c12,
    name: "admin-placements-cr"
  }, {
    path: "/admin/placements/ctb",
    component: _e7e5ce04,
    name: "admin-placements-ctb"
  }, {
    path: "/admin/placements/inbox",
    component: _366595b3,
    name: "admin-placements-inbox"
  }, {
    path: "/admin/placements/my-gp",
    component: _073b0c52,
    name: "admin-placements-my-gp"
  }, {
    path: "/admin/placements/queried-timesheets",
    component: _28d381da,
    name: "admin-placements-queried-timesheets"
  }, {
    path: "/admin/placements/test",
    component: _3a6eb816,
    name: "admin-placements-test"
  }, {
    path: "/admin/placements/working-by-consultant",
    component: _2494f5c2,
    name: "admin-placements-working-by-consultant"
  }, {
    path: "/admin/timesheets/authoriser-requests",
    component: _42dfb48f,
    name: "admin-timesheets-authoriser-requests"
  }, {
    path: "/admin/timesheets/authorisers",
    component: _4905b1de,
    name: "admin-timesheets-authorisers"
  }, {
    path: "/admin/timesheets/authorisers-advanced",
    component: _30f83be4,
    name: "admin-timesheets-authorisers-advanced"
  }, {
    path: "/admin/timesheets/by-consultant",
    component: _c08fd51e,
    name: "admin-timesheets-by-consultant"
  }, {
    path: "/admin/timesheets/cr",
    component: _1bf1bedf,
    name: "admin-timesheets-cr"
  }, {
    path: "/admin/timesheets/ctb",
    component: _686bdfd1,
    name: "admin-timesheets-ctb"
  }, {
    path: "/admin/timesheets/reports",
    component: _c511d702,
    name: "admin-timesheets-reports"
  }, {
    path: "/admin/tools/archived-candidates",
    component: _988854ee,
    name: "admin-tools-archived-candidates"
  }, {
    path: "/admin/tools/csv-import",
    component: _5b1afa64,
    name: "admin-tools-csv-import"
  }, {
    path: "/admin/tools/elasticsearch",
    component: _40307dee,
    name: "admin-tools-elasticsearch"
  }, {
    path: "/admin/tools/emails",
    component: _3459ed4e,
    name: "admin-tools-emails"
  }, {
    path: "/admin/tools/leaver-candidates",
    component: _077c7930,
    name: "admin-tools-leaver-candidates"
  }, {
    path: "/admin/tools/live-history",
    component: _7f3cef96,
    name: "admin-tools-live-history"
  }, {
    path: "/admin/tools/timesheets-cron",
    component: _f0d4a870,
    name: "admin-tools-timesheets-cron"
  }, {
    path: "/admin/tools/vincere-tools",
    component: _eed14628,
    name: "admin-tools-vincere-tools"
  }, {
    path: "/admin/users/admins",
    component: _00df6efc,
    name: "admin-users-admins"
  }, {
    path: "/admin/users/candidates",
    component: _40b1fdc8,
    name: "admin-users-candidates"
  }, {
    path: "/admin/users/companies",
    component: _5e64b439,
    name: "admin-users-companies"
  }, {
    path: "/admin/users/umbrella-companies",
    component: _6eb2b78a,
    name: "admin-users-umbrella-companies"
  }, {
    path: "/company/my-company/authorisation-requests",
    component: _5b409398,
    name: "company-my-company-authorisation-requests"
  }, {
    path: "/admin/users/companies/company-users",
    component: _553893c2,
    name: "admin-users-companies-company-users"
  }, {
    path: "/admin/users/admins/:uid",
    component: _214c181a,
    name: "admin-users-admins-uid"
  }, {
    path: "/admin/users/candidates/:uid",
    component: _be022e64,
    name: "admin-users-candidates-uid"
  }, {
    path: "/admin/users/companies/:uid",
    component: _d53cde06,
    name: "admin-users-companies-uid"
  }, {
    path: "/admin/users/company-users/:uid",
    component: _3dd73a00,
    name: "admin-users-company-users-uid"
  }, {
    path: "/company/my-company/candidate/:candidateID",
    component: _6088f78e,
    name: "company-my-company-candidate-candidateID"
  }, {
    path: "/company/my-company/placement/:placementID",
    component: _175c164e,
    name: "company-my-company-placement-placementID"
  }, {
    path: "/company/my-company/timesheet/:timesheetID",
    component: _e0ff87a4,
    name: "company-my-company-timesheet-timesheetID"
  }, {
    path: "/admin/invoices/:id",
    component: _069b25a2,
    name: "admin-invoices-id"
  }, {
    path: "/admin/placements/:id",
    component: _619ccd2c,
    name: "admin-placements-id"
  }, {
    path: "/admin/timesheets/:id",
    component: _18a6b5fd,
    name: "admin-timesheets-id"
  }, {
    path: "/admin/users/:uid",
    component: _405f4289,
    name: "admin-users-uid"
  }, {
    path: "/candidate/my-timesheets/:placementID",
    component: _a398536a,
    name: "candidate-my-timesheets-placementID"
  }, {
    path: "/candidate/my-timesheets/:placementID?/:timesheetID",
    component: _7b5e74e8,
    name: "candidate-my-timesheets-placementID-timesheetID"
  }, {
    path: "/authorisation-request/:linkID?",
    component: _3c3fa92e,
    name: "authorisation-request-linkID"
  }, {
    path: "/",
    component: _2acff034,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
