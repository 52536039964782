// Development environment config.
// This file is used if process.env.NODE_ENV !== 'production' && !process.env.BASE_URL
const configDev = {
  // Environment name
  environmentName: 'Local Development',
  // Project name
  projectName: 'Concorde Crisp',
  // Project description
  projectDescription: 'Concorde Recruitments Interoperable Software Platform.',
  // Url of the live app
  liveURL: 'https://concorde-crisp-app.appspot.com',
  // Url of the live API end point
  liveAPIURL: 'https://concorde-crisp-app.appspot.com/api',
  // Url of the local nuxt app in dev
  localURL: 'http://localhost:3000',
  // Url of the local cloud functions instance in dev
  localAPIURL: 'http://localhost:8080',
  // Config for the firebase project
  fireBaseConfig: {
    apiKey: 'AIzaSyC8oVjI-Qe5fPvvQ2OHNUXKQzzF2qxSdhk',
    authDomain: 'concorde-crisp-staging.firebaseapp.com',
    databaseURL: 'https://concorde-crisp-staging.firebaseio.com',
    projectId: 'concorde-crisp-staging',
    storageBucket: 'concorde-crisp-staging.appspot.com',
    messagingSenderId: '919985403216'
  },
  loqate: {
    key: 'BF96-EK76-HT89-AD31'
  },
  // String to be pre-pended to all cookies. Used to set and get all cookies to do with the app.
  cookiePrependKey: 'CONC_CRISP_',
  // Color of the nuxt-controlled page loading bar.
  loadingColor: '#4CAE50',
  // App theme colours passed to vuetify
  theme: {
    themes: {
      light: {
        // primary: '#C50D45', Updated 15/07/25
        primary: '#169FAE',
        // accent: '#01446A', Updated 15/07/25
        accent: '#00537F',
        secondary: '#E3F0F4',
        info: '#0D47A1',
        warning: '#ffb300',
        error: '#B71C1C',
        success: '#2E7D32'
      }
    }
  }
}
export default configDev
