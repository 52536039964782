<template lang="html">
  <v-app light>
    <generalAppToolbar hide-menu-icon />

    <v-main>
      <v-container class="pa-0">
        <nuxt />
      </v-container>
    </v-main>
    <!-- Snackbars -->
    <snackBars />

    <!-- here's a general confirmation dialog -->
    <confirmDialog />

    <!-- Here's the need help bottom sheet -->
    <needHelpSheet hide-bottom-bar />

    <!-- Here's the preloader -->
    <preLoader />
  </v-app>
</template>

<script>
import generalAppToolbar from '~/components/layout/generalAppToolbar'
import needHelpSheet from '~/components/layout/needHelpSheet'
import preLoader from '~/components/layout/preLoader'
import snackBars from '~/components/utils/snackBars'
import confirmDialog from '~/components/utils/confirmDialog'
export default {
  components: {
    preLoader,
    confirmDialog,
    needHelpSheet,
    snackBars,
    generalAppToolbar
  }
}
</script>

<style lang="css"></style>
