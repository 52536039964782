// This file contains a map - a simple array of objects with a condition and a key
// should be used in such a way that the function run

// a map of entityTypes and functions on how to get the correct type
// from an array made with databaseHelpers.getPathFromRef(docRef). eg:
// ['candidate', '12345']
const entityMap = [
  { function: (x) => x[0] === 'candidate' && x[1], type: 'candidate' },
  { function: (x) => x[0] === 'placement' && x[1], type: 'placement' },
  { function: (x) => x[0] === 'timesheet' && x[1], type: 'timesheet' }
]

module.exports = { entityMap }
