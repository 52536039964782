<template>
  <v-row class="justify-center">
    <v-col cols="12" class="text-center">
      <addressSearch :element="element" @addressFound="addressFound" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title><v-icon class="mr-4">place</v-icon>Your Address</v-card-title>

        <v-card-text class="text--primary">
          <v-subheader>Your Address</v-subheader>

          <v-row no-gutters class="justify-center">
            <v-col cols="12" sm="11">
              <v-text-field
                id="line 1"
                v-model="localAddress.address1"
                name="Address Line 1"
                label="Address Line 1"
                :required="required"
                :rules="required ? [(v) => !!v || 'Required'] : []"
              />
              <v-text-field
                id="street"
                v-model="localAddress.address2"
                name="Street"
                label="Address Line 2"
              />
              <v-text-field
                id="line 2"
                v-model="localAddress.address3"
                name="Address Line 3"
                label="Address Line 3"
              />
              <v-text-field id="city" v-model="localAddress.address4" name="City" label="Town / City" />
              <v-text-field
                id="postcode"
                v-model="localAddress.postcode"
                name="Postcode"
                label="Postcode"
                :required="required"
                :rules="required ? [(v) => !!v || 'Required'] : []"
              />
              <v-text-field
                id="country"
                v-model="localAddress.country"
                name="country"
                label="Country"
                disabled
                :required="required"
                :rules="required ? [(v) => !!v || 'Required'] : []"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import addressSearch from '~/components/utils/addressSearch' // <-- The search box - outputs the address shit
export default {
  components: { addressSearch },
  model: {
    prop: 'address',
    event: 'change'
  },
  props: {
    element: {
      type: String,
      default: 'addressSearch'
      // description: 'This is the element to bind the pca address instance to - useful when there is 2 address searches on a page.'
    },
    address: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autoFill: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      userID: 'user/userID'
    }),
    localAddress: {
      get() {
        return this.address || {}
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  mounted() {
    this.prefillAddress()
  },
  methods: {
    addressFound(address) {
      this.localAddress = address
    },
    prefillAddress() {
      if (this.userID && this.autoFill) {
        this.localAddress = { ...this.user.address } || { country: 'United Kingdom' }
      } else {
        this.localAddress = { country: 'United Kingdom' }
      }
    }
  }
}
</script>

<style scoped></style>
