export default (context, inject) => {
  const isValidMobileNumber = (v) => {
    const reg = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/gm
    return v && !!v.match(reg)
  }

  const isValidEmail = (v) => {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return v && !!v.match(reg)
  }

  const isValidNationalInsuranceNumber = (v) => {
    const reg = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]{0,1}$/
    return v && !!v.match(reg)
  }
  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const validation = () => {
    return { isValidMobileNumber, isValidEmail, isValidNationalInsuranceNumber }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('validation', validation())
}
