<template lang="html">
  <v-app light>
    <v-app-bar color="accent" app clipped-left dense>
      <img v-if="isHydrated && isMd" src="/concorde-logo-new-white.png" class="crisp-toolbar-logo" height="38px" />
    </v-app-bar>

    <v-navigation-drawer
      v-model="nav"
      app
      expand-on-hover
      permanent
      clipped
      :mini-variant.sync="mini"
    >
      <template #prepend>
        <template v-for="(item, i) in navItems">
          <v-subheader v-if="item.header" :key="i">{{ item.header }}</v-subheader>

          <v-list-item
            :key="`list-title-${i}`"
            exact
            nuxt
            :to="item.link"
            two-line
            active-class="secondary accent--text"
            @click="item.link ? () => { } : doMethod(item.action)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </template>

      <!-- Navigation Footer -->
      <template #append>
        <template v-for="(item, i) in navItemsBottom">
          <v-subheader v-if="item.header" :key="i">{{ item.header }}</v-subheader>

          <v-list-item
            :key="`list-title-${i}`"
            exact
            nuxt
            :to="item.link"
            two-line
            active-class="secondary accent--text"
            @click="item.link ? () => { } : doMethod(item.action)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </template>
    </v-navigation-drawer>

    <!-- <generalNav :miniable="isMd" style="z-index: 21;">
      <v-list class="pt-0">
        
      </v-list>
    </generalNav>-->
    <!-- <generalAppToolbar /> -->

    <v-main>
      <!-- Page view -->
      <nuxt />
    </v-main>

    <!-- Snackbars -->
    <snackBars />

    <!-- here's a general confirmation dialog -->
    <confirmDialog />

    <!-- Here's the need help bottom sheet -->
    <needHelpSheet hide-bottom-bar />

    <!-- Here's the preloader -->
    <preLoader />

    <!-- Here's the more details fullscreen dialog -->
    <needMoreDetailsDialog />

    <v-footer class="px-3 py-1">
      <v-spacer />
      <div>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon color="grey" dark small v-on="on" @click="gotoBugReport()">bug_report</v-icon>
          </template>
          <span>Send a bug report</span>
        </v-tooltip>-->

        <v-chip
          v-if="userData.userType == 'companyUser'"
          label
          small
          color="grey"
          text-color="white"
        >Company User</v-chip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip label small v-on="on">&copy; Concorde Group {{ new Date().getFullYear() }}</v-chip>
          </template>
          <span>
            This is CRISP v3-{{ buildNumber }} ({{ version }}) {{ environmentName }} - Developed by
            Carepenny.com
          </span>
        </v-tooltip>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import needMoreDetailsDialog from '~/components/user/needMoreDetailsDialog'
// import generalNav from '~/components/layout/generalNav'
// import generalAppToolbar from '~/components/layout/generalAppToolbar'
import needHelpSheet from '~/components/layout/needHelpSheet'
import preLoader from '~/components/layout/preLoader'
import snackBars from '~/components/utils/snackBars'
import confirmDialog from '~/components/utils/confirmDialog'
export default {
  components: {
    preLoader,
    confirmDialog,
    needHelpSheet,
    snackBars,
    // generalNav,
    // generalAppToolbar,
    needMoreDetailsDialog
  },
  data: () => ({
    isHydrated: false,
    navItems: [
      { title: 'Company Dashboard', icon: 'business', link: '/company/my-company' },
      {
        title: 'Timesheets Inbox',
        icon: 'mdi-calendar-multiple',
        link: '/company/my-company/authorisation-requests'
      },
      { title: 'Get Help', icon: 'help_outline', action: 'launchHelpSheet' },
      { title: 'Logout', icon: 'power_settings_new', action: 'confirmLogout' }
    ],
    navItemsBottom: [{ title: 'Logout', icon: 'power_settings_new', action: 'confirmLogout' }],
    appEngineVersion: process.env.appEngineVersion,
    environmentName: process.env.environmentName,
    nodeEnv: process.env.NODE_ENV,
    baseURL: process.env.appBaseURL, // Get enviroment vars
    version: process.env.version,
    buildNumber: process.env.buildNumber,
    mini: false,
    nav: true
  }),
  computed: {
    ...mapGetters({
      claims: 'user/claims',
      userData: 'user/userData'
    }),
    isSuperAdmin() {
      const { claims } = this
      return claims && claims.superAdmin
    },
    isAdmin() {
      const { claims } = this
      return claims && claims.admin
    },
    isMd() {
      // boolean to see if screensize is md or larger must be done after hydration in ssr
      return this.isHydrated ? this.$vuetify.breakpoint.mdAndUp : true
    }
  },
  mounted() {
    this.isHydrated = true
  },
  methods: {
    doMethod(name) {
      if (name) {
        return this[name]()
      }
      return this.$snack.error(`No method 'name' passed to doMethod()`)
    },
    launchHelpSheet() {
      return this.$store.commit('utils/needHelpSheet', true)
    },
    confirmLogout() {
      return this.$dialog.confirm(this.logout, {
        title: 'Log out',
        text: 'Are you sure you want to log out?',
        buttons: {
          confirm: 'Yes',
          cancel: 'No'
        }
      })
    },
    async logout() {
      await this.$user.logout()
    }
  }
}
</script>

<style lang="scss">
.crisp-toolbar {
  &-logo {
    max-height: 48px;
  }
}
</style>
