import databaseHelpers from '~/plugins/database/helpers.js'
import { entityMap } from './entityMap.js'
// const databaseHelpers = require('~/plugins/database/helpers.js')

export default ({ store, app }, inject) => {
  // Get an 'entityType' from the firestore document reference.
  const getEntityTypeFromDocRef = (docRef) => {
    const pathArray = databaseHelpers.getPathFromRef(docRef)
    if (!pathArray) {
      console.error(`getEntityTypeFromDocRef: No pathArray returned from getPathFromRef. docRef = ${docRef}`)
    }
    const result = entityMap.filter((item) => item.function(pathArray))
    return (result[0] && result[0].type) || pathArray[0] || 'unknown'
  }

  const append = async ({ docRef, updates, description, data }) => {
    const userName = store.getters['user/fullName']
    const actioner = { name: userName, id: app.$user.id() }
    const entityType = getEntityTypeFromDocRef(docRef)
    const historyDocRef = app.$db.collection('history').doc()
    await historyDocRef.set({
      entityType,
      docPath: databaseHelpers.getPathFromRef(docRef, { string: true }),
      updates,
      description,
      data,
      actioner,
      timestamp: new Date().toISOString()
    })
  }

  // Candidate entrypoint: this.$history.candidate(id).get()
  // just a shortcut to the firebase doc ref
  const candidate = (id) => {
    return app.$db.collection('history').where('docPath', '==', `candidate/${id}`)
  }
  // client entrypoint: this.$history.client(id).get()
  // just a shortcut to the firebase doc ref
  const client = (id) => {
    return app.$db.collection('history').where('docPath', '==', `client/${id}`)
  }
  // timesheet entrypoint: this.$history.timesheet(id).get()
  // just a shortcut to the firebase doc ref
  const timesheet = (id) => {
    return app.$db.collection('history').where('docPath', '==', `timesheet/${id}`)
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const historyHelper = () => {
    return { append, candidate, client, timesheet }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('history', historyHelper()) // this.$handleError
}
