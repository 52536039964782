import Vue from 'vue'

const currentlyChecking = []

const checkHeightForChangeAndUpdate = (el, oldHeight, delay, transitionTime, fineTune) => {
  // Set the max amount of times to check for new height just before browser repaint.
  const maxCheck = fineTune
  // keep track of the current check number
  let currentCheck = 0
  // function to check and loop until currentCheck === maxCheck
  const check = () => {
    // check to see if this should run and return if not
    if (currentCheck === maxCheck) {
      // remove element from the currentlyChecking array
      const i = currentlyChecking.indexOf(el)
      currentlyChecking.splice(i, 1)
      return false
    }
    // clock up currentCheck
    currentCheck++
    // get the newHeight (height is changed just before browser repaint)
    const newHeight = `${el.clientHeight}px`
    // check to see if newHeight is the same as oldHeight, if so, vue is still adding
    // or removing a child element and the parent has not changed height yet.
    if (newHeight === oldHeight) {
      // If heights are the same, loop this function just before each browser repaint.
      // If's below are workarounds for nuxt/ssr to make sure this is on a browser:
      // If no 'process', assume we're in browser otherwise, check we are in a browser
      if (!process || process.browser) {
        window.requestAnimationFrame(check)
      }
    } else {
      // Otherwise, the height has changed and we're in limbo before browser has visualised the changes.
      // So let's catch that and set the element's height to the old height before the change.
      el.style.height = oldHeight
      // Then we'll delay setting the new height as per the user's delay setting
      setTimeout(() => {
        // when the delay is finished, set the height of the element to the new height
        el.style.height = newHeight
        // wait until the height change transition has finished as per the user's transition setting.
        setTimeout(() => {
          // change the element's height back to 'auto' so that
          // it's height will change again the next time a child is added or removed.
          el.style.height = 'auto'
          // and remove element from the currentlyChecking array
          const i = currentlyChecking.indexOf(el)
          currentlyChecking.splice(i, 1)
        }, transitionTime)
      }, delay)
    }
  }
  // start the looping function
  check()
}

const smoothResize = (el, binding) => {
  // Check to see if we're currently checking and waiting for the el
  if (currentlyChecking.includes(el)) {
    // if we are, exit as it's been triggered twice.
    return false
  } else {
    // add the element to the updates array.
    currentlyChecking.push(el)
  }
  if (binding.value && binding.value.deactivate) {
    return true
  }
  // first things first, set the element's height to auto so that
  // it's height will change the next time a child is added or removed.
  el.style.height = 'auto'
  // transitionTime is the amount of time in ms that the transition should take.
  const transitionTime = (binding.value && binding.value.transition) || 300
  // fineTune is to adjust how many browser repaints to loop for in checkHeightForChangeAndUpdate()
  const fineTune = (binding.value && binding.value.fineTune) || 10
  // delay is to delay the setting of the newHeight
  const delay = (binding.value && binding.value.delay) || 100
  // overflow lets the user set the overflow of the container
  const overflow = (binding.value && binding.value.overflow) || 'hidden'
  // get the old height
  const oldHeight = `${el.clientHeight}px`
  // set the transition and overflow styles.
  el.style.transition = `all ${transitionTime}ms cubic-bezier(0.0, 0.0, 0.2, 1)`
  el.style.overflow = overflow
  // Run the function to check for height change and adjust accordingly
  checkHeightForChangeAndUpdate(el, oldHeight, delay, transitionTime, fineTune)
}

Vue.directive('smooth-resize', {
  componentUpdated(el, binding, vnode) {
    smoothResize(el, binding, vnode)
  },
  updated(el, binding, vnode) {
    smoothResize(el, binding, vnode)
  }
})
