import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  return new VuexPersistence({
    reducer: (state) => ({
      // reduce what datapoints in the state shold be stored.
      // ONLY the datapoints listed below will be stored.
      layout: {
        miniDrawer: state.layout.miniDrawer
      }
    }),
    filter: ({ type }) => {
      // { type, paylaod }
      // filter what mutations should trigger a write to local storage.
      // will still only store the data defined in the reducer above.
      // type is the name of the mutation that was called. eg: 'layout/miniDrawer'
      // payload is the actual data that was passed to the mutation. eg: true
      if (type === 'layout/miniDrawer') {
        return true
      }
      return false
    }
  }).plugin(store)
}
