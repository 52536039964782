// import and register your global vue components here
import Vue from 'vue'
import JsonViewer from 'vue-json-viewer'
import VueApexCharts from 'vue-apexcharts'
import CodeView from '~/components/utils/CodeView'

// Pretty JSON
Vue.component('json-viewer', JsonViewer)
// charts
Vue.component('apexchart', VueApexCharts)
// custom
Vue.component('CodeView', CodeView)
