const nullState = {
  authID: null,
  userID: null,
  claims: null,
  userData: {}
}

export const state = () => ({
  ...nullState
})

export const mutations = {
  setAuthID(state, authID) {
    state.authID = authID
  },
  setUserData(state, data) {
    state.userData = data || {}
  },
  claims(state, claims) {
    state.claims = claims
    state.userID = claims.userID || null
  },
  clear(state) {
    for (const key in nullState) {
      state[key] = nullState[key]
    }
  }
}

export const actions = {
  // check to see if the user's data is missing anything like firstName etc.
  // if it is, show a dialog box directing them the their my-account page.
  async checkForMissingUserDatapoints(context, doc) {
    const { userType } = doc
    // set some always required fields
    const required = [
      { key: 'firstName', display: 'first name' },
      { key: 'lastName', display: 'last name' },
      { key: 'email', display: 'email address' }
    ]
    // add to required based on user type
    if (userType === 'candidate') {
      required.push({
        key: 'nationalInsuranceNumber',
        display: 'National Insurance Number'
      })
    }

    // check which fields are missing from the user doc.
    const missing = required.filter(({ key }) => !doc[key]).map((x) => x.display)

    // if none are missing, we're good to go!
    if (!missing.length) {
      return true
    }

    // otherwise, we'll make up the text for the dialog box to show the user
    // the regex in replace() just finds the last comma and replaces it with 'and'
    // to make the text read better.
    const text = `We need some more information from you to finish setting up your account. We just need your
    ${missing.join(', ').replace(/, ([^,]*)$/, ' and $1')}.`

    // make the url path that the user should be sent to
    const path = `${
      userType === 'superAdmin' || userType === 'admin'
        ? '/admin/account/settings'
        : '/' + userType + '/my-account'
    }`
    // launch the dialog box to show to user.
    return this.$dialog.confirm(async () => this.$router.push(path), {
      title: 'Finish setting up your account',
      text,
      buttons: {
        confirm: 'Complete now',
        cancel: 'Later'
      }
    })
  },
  // watch user data
  async watchUserData({ dispatch }) {
    const customClaims = await this.$user.getCustomClaims()
    const { userID, candidate, company, companyUser, admin, superAdmin, authoriser } = customClaims
    if (candidate) {
      return dispatch('candidate/initStore', true, { root: true })
    }
    if (company) {
      return dispatch('company/initStore', true, { root: true })
    }
    if (companyUser) {
      return dispatch('companyUser/initStore', true, { root: true })
    }
    if (admin || superAdmin) {
      return dispatch('admin/initStore', true, { root: true })
    }
    if (authoriser) {
      return dispatch('authoriser/initStore', true, { root: true })
    }
    if (!userID) {
      console.error('watchUserData: no userID')
      return true
    }
  },
  // Stop watching user data
  stopWatchUserData() {
    this.$watchers.stop('userData')
  }
}

export const getters = {
  authID: (state) => state.authID,
  user: (state) => ({ ...state }),
  userData: (state) => state.userData,
  claims: (state) => state.claims,
  userID: (state) => state.userID,
  fullName: (state) => {
    const userData = state.userData || {}
    const { firstName, lastName } = userData
    return `${firstName ? firstName + ' ' : ''}${lastName || ''}`
  }
}
