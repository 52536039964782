export const state = () => ({
  confirm: {
    toggle: false,
    text: null,
    title: null,
    buttons: {
      confirm: null,
      cancel: null
    },
    method: null,
    resolve: null,
    reject: null
  }
})

export const mutations = {
  setConfirmDialog(state, { text, title, buttons, method, resolve, reject } = {}) {
    state.confirm.text = text
    state.confirm.title = title
    state.confirm.buttons = buttons
    state.confirm.method = method
    state.confirm.resolve = resolve
    state.confirm.reject = reject
    state.confirm.toggle = true
  },
  clearConfirmDialog(state) {
    state.confirm.toggle = false
    state.confirm.method = null
    state.confirm.reject({
      code: 'dialog/userCancel',
      message: 'User cancelled'
    })
  }
}

export const actions = {
  async completeConfirmAction({ state, commit }) {
    try {
      await state.confirm.method()
      state.confirm.resolve()
      commit('clearConfirmDialog')
    } catch (e) {
      state.confirm.reject(e)
      throw new Error(e)
    }
  }
}

export const getters = {
  confirm: (state) => state.confirm
}
