const nullState = {
  email: null,
  firstName: '',
  lastName: '',
  mobileNumber: '',
  settings: {},
  id: null,
  queriedTS: []
}

export const state = () => ({
  ...nullState
})

export const mutations = {
  setAdminData(state, data) {
    for (const key in state) {
      if (data && key in data) {
        state[key] = data[key]
      }
    }
  }
}

export const actions = {
  // initiate the store by watching some firebase datapoints
  initStore({ dispatch }) {
    dispatch('watchAdminData')
  },
  async watchAdminData({ commit, dispatch, rootState }, { userID, mode = 'userID' } = {}) {
    try {
      let queryValue
      const claims = await this.$user.getCustomClaims()
      const collectionName = claims.superAdmin ? 'superAdmins' : claims.admin ? 'admins' : null
      if (!collectionName) {
        throw new Error('watchAdminData: Admin type not found from custom claims.')
      }
      let dbRef = this.$db.collection(collectionName)
      if (mode === 'userID') {
        queryValue = await this.$user.getUserID()
        dbRef = dbRef.where('accessUser', 'array-contains', queryValue || false)
      } else if (mode === 'email') {
        queryValue = rootState.user.claims && rootState.user.claims.email
        dbRef = dbRef.where('accessEmail', 'array-contains', queryValue || false)
      } else if (mode === 'phone') {
        queryValue = rootState.user.claims && rootState.user.claims.phone
        dbRef = dbRef.where('accessPhone', 'array-contains', queryValue || false)
      } else if (mode === 'searchEnd') {
        throw new Error('Could not find admin doc by userID, email or phone.')
      }
      // Set the amount of queried timesheets for admin (Concorde) users to make them know that some authoriser
      // had set the 'rejected' status for some candidate
      this.$watchers.set(
        'adminQueriedTS',
        this.$db.collection('timesheets').where('status', '==', 'rejected'),
        (snap) => {
          try {
            const data = snap.docs.map((x) => x.data().id)
            commit('setAdminData', { queriedTS: data })
          } catch (e) {
            dispatch('stopWatchingAdminQueriedTS')
          }
        }
      )

      return this.$watchers.set('adminData', dbRef, (snap) => {
        const data = snap.size ? snap.docs.map((x) => x.data())[0] : null
        if (!data) {
          dispatch('stopWatchingAdminData')
          const newMode = mode === 'userID' ? 'email' : mode === 'email' ? 'phone' : 'searchEnd'
          dispatch('watchAdminData', { userID, mode: newMode })
        } else {
          commit('setAdminData', data)
          return commit('user/setUserData', data, { root: true })
        }
      })
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching admin data doc
  stopWatchingAdminData() {
    return this.$watchers.stop('adminQueriedTS')
  },
  stopWatchingAdminQueriedTS() {
    return this.$watchers.stop('adminData')
  }
}

export const getters = {
  id: (state) => state.id,
  data: (state) => state,
  queriedTSAmount: (state) => state.queriedTS.length,
  queriedTS: (state) => state.queriedTS
}
