// import {db} from '~/plugins/firebase'
export const state = () => ({
  current: {},
  pageTitle: ''
})

export const mutations = {
  current(state, data) {
    state.current = data
  },
  pageTitle(state, data) {
    state.pageTitle = data
  }
}

export const getters = {
  current: (state) => state.current,
  pageTitle: (state) => state.pageTitle
}
