export default ({ app, $axios, store, redirect }, inject) => {
  // sign up a new user
  const signup = async (email, pass) => {
    if (!email) {
      return app.$snack.info('Please enter your email.')
    }
    if (!pass) {
      return app.$snack.info('Please create a password.')
    }
    // see if the user has been added by concorde staff, preventing random users signing up, or users
    // getting the welcome email and signing up with a different email address then not seeing their placements!
    const { data } = await app.$axios.post('/api/user/login-preflight', { email })
    const { permitted } = data
    if (!permitted) {
      const err = new Error('Email does not exist in system.')
      err.code = 'error/email-does-not-exist'
      throw err
    }
    const { user } = await app.$auth.createUserWithEmailAndPassword(email, pass)
    setAuthID(user.uid)
    await setToken()
    return user
  }

  // Log a user in with email and password
  const login = async (email, pass) => {
    if (!email) return app.$snack.info('Please enter your email.')
    if (!pass) return app.$snack.info('Please enter your password.')
    const { user } = await app.$auth.signInWithEmailAndPassword(email, pass)
    setAuthID(user.uid)
    await setToken()
    const claims = await getCustomClaims()
    watchForCustomClaimsUpdate(user.uid)
    return app.$routeHelper.redirectHome({ claimsObject: claims })
  }

  // log a user out
  const logout = async () => {
    try {
      await app.$watchers.stopAll()
      await unsetToken()
      await unsetAuthID()
      await app.$auth.signOut()
      store.dispatch('clearStore')
      return redirect('/log-in')
    } catch (e) {
      app.$handleError(e)
      return redirect('/log-in')
    }
  }

  // get and set a user's token, adding it to axios too for reqs to api
  // also store it as a cookie for use in nuxtServerInit
  const setToken = async () => {
    if (app.$auth.currentUser) {
      const csrfToken = app.$cookies.browser.get('csrfToken')
      const idToken = await app.$auth.currentUser.getIdToken(true)
      const { data } = await $axios.post('/api/auth/session-token', { csrfToken, idToken })
      const { token } = data

      console.log('Updating apollo auth token')

      await app.$apolloHelpers.onLogin(token) // Set apollo auth token

      return token
    } else {
      console.error('$user.setToken: Unable to get user auth token no app.$auth.currentUser')
      return false
    }
  }

  // unset a user's token
  // has to happen server side as cookies are http only for security
  const unsetToken = async () => {
    try {
      await app.$axios.get('/api/auth/auth-cookie-clear')
    } catch (e) {
      app.$handleError(e)
      return redirect('/log-in')
    }
  }

  // set a user's ID
  const setAuthID = (id = null) => {
    if (id || app.$auth.currentUser) {
      id = id || app.$auth.currentUser.uid
      store.commit('user/setAuthID', id)
      return id
    } else {
      store.commit('user/setAuthID', null)
      console.error('user.$setAuthID: no app.$auth.currentUser')
    }
  }

  // unset a user's ID
  const unsetAuthID = async () => {
    return store.commit('user/setAuthID', null)
  }

  // get a user's auth ID
  const getAuthID = () => {
    return store.getters['user/authID']
  }

  // get a users doc id - their doc in the users collection in the database
  // this should be on their customClaims.userID
  const getUserID = async () => {
    const userID = await getCustomClaims('userID')
    if (!userID) {
      throw new Error('Could not find userID on claims object. Contact support.')
    }
    return userID
  }

  // Get a user's custom claims object from auth
  const getCustomClaims = async (claimName) => {
    const idToken = app.$auth.currentUser ? await app.$auth.currentUser.getIdTokenResult() : null
    if (!idToken) {
      throw new Error('User token not found - cannot get custom claims object')
    }
    const { claims } = idToken
    store.commit('user/claims', claims)
    return claimName ? claims[claimName] : claims
  }

  // return the custom claims stored in vuex
  const customClaims = () => {
    return store.getters['user/claims']
  }

  const updateUserTokenAndRedirect = async ({ isTokenRefreshRequest = false, token }) => {
    // Get and save the user's auth token for hitting the API in cloud Functions
    if (!token) {
      await setToken()
    }
    // Get the user's custom claims object for userControl.
    const customClaims = await getCustomClaims()
    if (app.$routeHelper.isRedirectHomeRoute(store.getters['routes/current']) || isTokenRefreshRequest) {
      return app.$routeHelper.redirectHome({ claimsObject: customClaims })
    }
  }

  const watchForCustomClaimsUpdate = (uid) => {
    // if the tokenpush watcher is already active, we don't need to do this again
    if (app.$watchers.isActive('tokenPush')) return

    const dbRef = app.$db.doc(`tokenPush/${uid}`)
    app.$watchers.set(
      'tokenPush',
      dbRef,
      async (doc) => {
        try {
          if (!doc.exists) {
            return dbRef.set({ lastUpdate: null }, { merge: true })
          }
          const { lastUpdate, refreshToken } = doc.data()
          if (!lastUpdate || lastUpdate < refreshToken) {
            await Promise.all([
              updateUserTokenAndRedirect({ isTokenRefreshRequest: false }),
              dbRef.set({ lastUpdate: new Date().toISOString() }, { merge: true })
            ])
            return store.dispatch('user/watchUserData')
          } else {
            return true
          }
        } catch (e) {
          console.error('watchForCustomClaimsUpdate1', e)
          return redirect('/log-in')
        }
      },
      (e) => {
        console.error('watchForCustomClaimsUpdate2', e)
        return app.$routeHelper.redirectHome({})
      }
    )
  }

  const onAuthStateChanged = async () => {
    return app.$auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          // Add user context to sentry if it exists on the app...
          // Note sentry is disabled in dev, see nuxt.config.js
          if (app.$sentry) {
            app.$sentry.configureScope((scope) => {
              scope.setUser({ email: user.email })
            })
            // Tell Sentry breadcrumbs the user logged in...
            app.$sentry.addBreadcrumb({
              category: 'auth',
              message: 'Authenticated user ' + user.email,
              level: app.$sentry.Severity.Info
            })
          }

          setAuthID(user.uid)
          // We set token again here to get a fresh session cookie token from the api service
          await setToken()
          store.dispatch('user/watchUserData', user)
          watchForCustomClaimsUpdate(user.uid)
          if (app.$routeHelper.isRedirectHomeRoute(app.$route)) {
            return app.$routeHelper.redirectHome()
          }
        } else {
          app.$watchers.stopAll()
          await unsetToken()
          await unsetAuthID()
        }
      } catch (e) {
        app.$handleError(e)
        return redirect('/log-in')
      }
    })
  }

  // Checks for existing users
  const checkEmailExists = async (email, types = []) => {
    let userTypes

    // If no 'types' is passed into this function, check all user types
    if (!types.length) {
      userTypes = ['superAdmins', 'admins', 'companies', 'companyUsers', 'candidates', 'authorisers']
    } else {
      userTypes = types
    }

    console.log(`Checking if user with ${email} has any of the following accounts: `, userTypes)

    const promises = userTypes.map((type) => app.$db.collection(type).where('email', '==', email).get())
    const results = await Promise.all(promises)
    const exists = results.map((x) => !!x.size).some((x) => x)
    return exists
  }

  const checkNationalInsuranceNumberExists = async (num) => {
    const result = await app.$db.collection('users').where('nationalInsuranceNumber', '==', num).get()
    return result.size
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const userHelper = () => {
    return {
      signup,
      login,
      logout,
      setToken,
      unsetToken,
      setAuthID,
      unsetAuthID,
      id: getAuthID,
      getAuthID,
      getUserID,
      customClaims,
      getCustomClaims,
      watchForCustomClaimsUpdate,
      onAuthStateChanged,
      checkEmailExists,
      updateUserTokenAndRedirect,
      checkNationalInsuranceNumberExists
    }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('user', userHelper()) // this.$user
}
