var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',{attrs:{"mode":"out-in"}},[(!_vm.loading)?_c('div',{key:"image",staticClass:"fullScreen",class:{
      'fullScreen--contain': _vm.contain,
      'fullScreen--padded': _vm.padded,
      miniNav: _vm.miniNav,
      toolbarExtended: _vm.toolbarExtended,
      absoluteCenter: _vm.absoluteCenter
    },style:({ backgroundImage: _vm.backgroundImage, 'background-position': _vm.position })},[(_vm.loadingBar)?_c('div',{staticClass:"fullScreen--progress"},[_c('v-progress-linear',{staticClass:"mt-0",attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{key:"loading",staticClass:"fullScreen",class:{
      'fullScreen--contain': _vm.contain,
      'fullScreen--padded': _vm.padded,
      miniNav: _vm.miniNav,
      toolbarExtended: _vm.toolbarExtended,
      absoluteCenter: _vm.absoluteCenter
    },style:({
      backgroundImage: _vm.backgroundImageLoading,
      'background-position': _vm.position
    })},[(_vm.loadingBar)?_c('div',{staticClass:"fullScreen--progress"},[_c('v-progress-linear',{staticClass:"mt-0",attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }