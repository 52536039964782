const getPathFromRef = (docRef, options = { string: false }) => {
  if (!docRef) {
    console.error('getPathFromRef: No docRef')
  }
  const pathArray = (docRef && docRef._path && docRef._path.segments) || (docRef && docRef.path) || false
  // if the path array is a string
  if (typeof pathArray === 'string') {
    // return the string if the user asked for it in options.string
    // or else, make it into an array and return that.
    return options.string ? pathArray : pathArray.split('/')
  }
  // if the pathArray is an array
  if (Array.isArray(pathArray)) {
    // return the array if the user asked for it by ommitting options.string
    // or else, make it into a string and return that.
    return options.string ? pathArray.join('/') : pathArray
  }
  // otherwise return false
  return false
}
export default {
  getPathFromRef
}
