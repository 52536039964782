// import {db} from '~/plugins/firebase'
export const state = () => ({
  miniNav: false,
  needHelpSheet: false,
  addUserFab: false
})

export const mutations = {
  miniNav(state, data) {
    state.miniNav = data
    this.$cookies.set({ miniNav: `${data}` })
  },
  needHelpSheet(state, data) {
    state.needHelpSheet = data
  },
  addUserFab(state, data) {
    state.addUserFab = data
  }
}

export const getters = {
  miniNav: (state) => state.miniNav,
  needHelpSheet: (state) => state.needHelpSheet,
  addUserFab: (state) => state.addUserFab
}
