<template>
  <v-text-field
    :id="element"
    v-model="addressSearch"
    flat
    solo
    :label="label"
    append-icon="search"
    autocomplete="new-password"
    @focus="label = 'Just start typing your address or postcode'"
    @blur="label = 'Enter your postcode to look up your address'"
  />
</template>

<script>
import Vue from 'vue'
import LoadScript from '~/plugins/loadScript'
Vue.use(LoadScript)
export default {
  props: {
    element: {
      type: String,
      default: 'addressSearch'
    }
  },
  data() {
    return {
      label: 'Start typing in this box (or complete manually)',
      addressSearch: '',
      address: {
        pcaAddress: null,
        postcode: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        country: 'United Kingdom'
      }
    }
  },
  async mounted() {
    await this.$loadScript('https://services.postcodeanywhere.co.uk/js/address-3.91.js')
    // ********** PCA ADDRESS SEARCH ********* //
    if (process.browser) {
      // eslint-disable-next-line no-undef
      const control = new pca.Address([{ element: this.element, field: '', mode: pca.fieldMode.SEARCH }], {
        key: process.env.LOQATE_API_KEY,
        search: { countries: 'GBR' },
        setCountryByIP: false
      })
      control.listen('populate', (address) => {
        const addressObject = {
          addressSearch: this.addressSearch,
          address1: address.BuildingNumber || address.BuildingName || address.Company,
          address2: address.Street,
          address3: address.Line2,
          address4: address.City,
          pcaAddress: address,
          postcode: address.PostalCode,
          country: 'United Kingdom'
        }
        this.$emit('addressFound', addressObject)
      })
    }
    // ********** END PCA ADDRESS SEARCH ********* //
  },
  beforeDestroy() {
    this.$unloadScript('https://services.postcodeanywhere.co.uk/js/address-3.91.js')
  },
  head() {
    return {
      link: [{ rel: 'stylesheet', href: 'https://services.postcodeanywhere.co.uk/css/address-3.91.css' }]
    }
  }
}
</script>
