// This is the DYNAMIC config file which will get its config from environment vars.
// This file is NOT used in development environments. See config-dev.js for that.
const appBaseURL = process.env.BASE_URL || 'http://localhost'
const apiKey = process.env.CRISP_FIREBASE_API_KEY
const authDomain = process.env.CRISP_FIREBASE_AUTH_DOMAIN
const databaseURL = process.env.CRISP_FIREBASE_DATABASE_URL
const projectId = process.env.CRISP_FIREBASE_PROJECT_ID
const storageBucket = process.env.CRISP_FIREBASE_STORAGE_BUCKET
const messagingSenderId = process.env.CRISP_FIREBASE_MESSAGING_SENDER_ID
const loqateApiKey = process.env.LOQATE_API_KEY

const configDynamic = {
  // Environment name:
  environmentName: process.env.ENVIRONMENT_NAME ? process.env.ENVIRONMENT_NAME : 'Unknown Environment',
  // Project name
  projectName: 'Concorde Crisp',
  // Project description
  projectDescription: 'Concorde Recruitment Online Timesheets',
  // Url of the live app
  liveURL: appBaseURL,
  // Url of the live API end point
  liveAPIURL: appBaseURL + '/api',
  // Url of the local nuxt app in dev
  localURL: 'http://' + appBaseURL + ':3000',
  // Url of the local cloud functions instance in dev
  localAPIURL: 'http://' + appBaseURL + ':8080',
  // Config for the firebase project
  fireBaseConfig: {
    apiKey: apiKey,
    authDomain: authDomain,
    databaseURL: databaseURL,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId
  },
  loqate: {
    key: loqateApiKey
  },
  // String to be pre-pended to all cookies. Used to set and get all cookies to do with the app.
  // TODO: Should this be dynamic based on the environment, too?
  cookiePrependKey: 'CONC_CRISP_',
  // Color of the nuxt-controlled page loading bar.
  loadingColor: '#4CAE50',
  // App theme colours passed to vuetify
  theme: {
    themes: {
      light: {
        // primary: '#C50D45', Updated 15/07/25
        primary: '#169FAE',
        // accent: '#01446A', Updated 15/07/25
        accent: '#00537F',
        secondary: '#E3F0F4',
        info: '#0D47A1',
        warning: '#ffb300',
        error: '#B71C1C',
        success: '#2E7D32'
      }
    }
  }
}
export default configDynamic
