import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

// set default timezone
moment.tz.setDefault('Europe/London')

export default ({ app }, inject) => {
  const getThisMonday = ({ format = '' } = {}) => {
    const monday = moment().isoWeekday(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return format ? monday.format(format) : monday
  }

  const getThisTuesday = ({ format = '' } = {}) => {
    const monday = moment().isoWeekday(2).set({ hour: 15, minute: 30, second: 0, millisecond: 0 })
    return format ? monday.format(format) : monday
  }

  const getThisWednesday = ({ format = '' } = {}) => {
    const wednesday = moment().isoWeekday(3).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return format ? wednesday.format(format) : wednesday
  }

  const getLastSunday = ({ format = '' } = {}) => {
    const sunday = moment()
      .isoWeekday(7)
      .subtract(1, 'week')
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    return format ? sunday.format(format) : sunday
  }

  const isMonday = (date) => {
    if (!date) {
      return app.$snack.error('Must pass a date to momentHelpers.isMonday()', date)
    }
    return moment(date).format('dddd') === 'Monday'
  }

  const isTuesday = (date) => {
    if (!date) {
      return app.$snack.error('Must pass a date to momentHelpers.isTuesday()', date)
    }
    return moment(date).format('dddd') === 'Tuesday'
  }

  const isWednesday = (date) => {
    if (!date) {
      return app.$snack.error('Must pass a date to momentHelpers.isWednesday()', date)
    }
    return moment(date).format('dddd') === 'Wednesday'
  }

  const isSunday = (date) => {
    if (!date) {
      return app.$snack.error('Must pass a date to momentHelpers.isSunday()', date)
    }
    return moment(date).format('dddd') === 'Sunday'
  }

  const currentWeek = () => {
    const weekStart = moment().isoWeekday(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return weekStart.isoWeek()
  }

  const getWeek = (date) => {
    if (!date) {
      return app.$snack.error('Pass a valid date to momentHelpers.getWeek. date = ', date)
    }
    const weekStart = moment(date).isoWeekday(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return weekStart.isoWeek()
  }

  const getMondayOfDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getMondayOfDate. date = ${date}`)
    }
    return moment(date).isoWeekday(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString()
  }

  const getTuesdayOfDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getMondayOfDate. date = ${date}`)
    }
    return moment(date).isoWeekday(2).set({ hour: 15, minute: 30, second: 0, millisecond: 0 }).toISOString()
  }

  const getSundayOfDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getSundayOfDate. date = ${date}`)
    }
    return moment(date).isoWeekday(7).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toISOString()
  }

  const getSundayBeforeDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getSundayBeforeDate. date = ${date}`)
    }
    return moment(date)
      .isoWeekday(7)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .subtract(1, 'week')
      .toISOString()
  }

  const getWednesdayOfDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getWednesdayOfDate. date = ${date}`)
    }
    return moment(date).isoWeekday(3).set({ hour: 10, minute: 30, second: 0, millisecond: 0 }).toISOString()
  }

  const getWednesdayBeforeDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getWednesdayBeforeDate. date = ${date}`)
    }
    return moment(date)
      .isoWeekday(3)
      .set({ hour: 10, minute: 30, second: 0, millisecond: 0 })
      .subtract(1, 'week')
      .toISOString()
  }

  const getMondayBeforeDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getMondayBeforeDate. date = ${date}`)
    }
    return moment(date)
      .isoWeekday(1)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .subtract(1, 'week')
      .toISOString()
  }

  const getMondayAfterDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getMondayAfterDate. date = ${date}`)
    }
    return moment(date)
      .isoWeekday(1)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .add(1, 'week')
      .toISOString()
  }

  const getWednesdayAfterDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getWednesdayAfterDate. date = ${date}`)
    }
    return moment(date)
      .isoWeekday(3)
      .set({ hour: 10, minute: 29, second: 0, millisecond: 0 })
      .add(1, 'week')
      .toISOString()
  }

  // get the week commencing dates formatted for Concorde.
  // pass in start to get just the start date, or end for just the end date.
  const weekCommencing = (date, { start, end } = {}) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.weekCommencing. date = ${date}`)
    }
    if (start && !end) {
      return moment(getMondayOfDate(date)).format('DD/MM/YYYY')
    } else if (end && !start) {
      return moment(getSundayOfDate(date)).format('DD/MM/YYYY')
    } else {
      return `${moment(getMondayOfDate(date)).format('DD/MM/YYYY')} to ${moment(getSundayOfDate(date)).format(
        'DD/MM/YYYY'
      )}`
    }
  }

  const weekCommencingNewStarters = (date, { start, end } = {}) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.weekCommencingNewStarters. date = ${date}`)
    }
    if (start && !end) {
      return moment(getWednesdayOfDate(date)).format('DD/MM/YYYY')
    } else if (end && !start) {
      return moment(getSundayOfDate(date)).format('DD/MM/YYYY')
    } else {
      return `${moment(getWednesdayOfDate(date)).format('DD/MM/YYYY')} to ${moment(
        getSundayOfDate(date)
      ).format('DD/MM/YYYY')}`
    }
  }

  const weekCommencingCTBReport = (date, { start, end } = {}) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.weekCommencingCTBReport. date = ${date}`)
    }
    if (start && !end) {
      return moment(getWednesdayBeforeDate(date)).format('DD/MM/YYYY')
    } else if (end && !start) {
      return moment(getWednesdayOfDate(date)).format('DD/MM/YYYY')
    } else {
      return `${moment(getWednesdayBeforeDate(date)).format('DD/MM/YYYY')} to ${moment(
        getWednesdayOfDate(date)
      ).format('DD/MM/YYYY')}`
    }
  }

  const getFirstMondayAfterDate = (date) => {
    if (!date) {
      throw new Error(`Pass a valid date to momentHelpers.getFirstMondayAfterDate. date = ${date}`)
    }
    const dayINeed = 1 // for Monday
    const dayOfDate = moment(date).isoWeekday()
    // if we haven't yet passed the day of the week that I need:
    if (dayOfDate <= dayINeed) {
      // then just give me this week's instance of that day
      return getMondayOfDate(date)
    } else {
      // otherwise, give me *next week's* instance of that same day
      return getMondayOfDate(moment(date).add(1, 'weeks').toISOString())
    }
  }

  // concorde has 3 different week numbers.
  // calendarWeek is the actual week from 1st Jan
  const getCalendarWeek = (date) => {
    if (!date) {
      return app.$snack.error('Pass a valid date to momentHelpers.getWeek. date = ', date)
    }
    const weekStart = moment(date).isoWeekday(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return weekStart.isoWeek()
  }

  // Y - HMRC tax year week (In the brackets)
  // revenueWeek is the week from the first Monday after or including 6th April (Teaching Bank Week)
  const getRevenueWeek = (date) => {
    if (!date) {
      return app.$snack.error('Pass a valid date to momentHelpers.getRevenueWeek. date = ', date)
    }
    let startDate
    // yearStart is the monday after or equal to the 6th april in date.year
    const dateISO = moment(date).toISOString()
    const currentYearStart = moment(dateISO)
      .set({ month: 3, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString()
    const currentYearStartMonday = getFirstMondayAfterDate(currentYearStart)
    // if date is less than that, yearStart is the monday after or equal to the 6th april of date.year -1 (previous year)
    const diff = moment(dateISO).diff(moment(currentYearStartMonday), 'seconds')
    if (diff < 0) {
      const previousYear = moment(dateISO).subtract(1, 'years').toISOString()
      const previousYearStart = moment(previousYear)
        .set({ month: 3, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString()
      const previousYearStartMonday = getFirstMondayAfterDate(previousYearStart)
      startDate = previousYearStartMonday
    } else {
      startDate = currentYearStartMonday
    }
    // get the difference in weeks between yearStart and date.
    const week = moment(date).diff(moment(startDate), 'weeks')
    return week + 1
  }

  // X - financialWeek is the week from the first Monday after or including 1st August
  // Format is X(Y) where X is the academic week and Y is the HMRC tax week
  const getFinancialWeek = (date) => {
    if (!date) {
      return app.$snack.error('Pass a valid date to momentHelpers.getFinancialWeek. date = ', date)
    }
    let startDate
    // yearStart is the monday after or equal to the 6th april in date.year
    const dateISO = moment(date).toISOString()
    const currentYearStart = moment(dateISO)
      .set({ month: 6, date: 31, hour: 1, minute: 0, second: 0, millisecond: 0 })
      .toISOString()
     const currentYearStartMonday = getFirstMondayAfterDate(currentYearStart)
    // if date is less than that, yearStart is the monday after or equal to the 6th april of date.year -1 (previous year)
    const diff = moment(dateISO).diff(moment(currentYearStartMonday), 'seconds')
    if (diff < 0) {
      const previousYear = moment(dateISO).subtract(1, 'years').toISOString()
      const previousYearStart = moment(previousYear)
        .set({ month: 6, date: 31, hour: 1, minute: 0, second: 0, millisecond: 0 })
        .toISOString()
      const previousYearStartMonday = getFirstMondayAfterDate(previousYearStart)
      startDate = previousYearStartMonday
    } else {
      startDate = currentYearStartMonday
    }
    // get the difference in weeks between yearStart and date.
    const week = moment(date).diff(moment(startDate), 'weeks')
    // return week plus one as the week in question is the diff + 1
    return week + 1
  }

  // take in a date and display it as financialWeek(revenueWeek) commencing weekStart - weekEnd
  const concordeWeekDisplay = (date) => {
    return `${getFinancialWeek(date)}(${getRevenueWeek(date)})`
  }

  // add to a date
  const addToDate = (date, amount, unit) => {
    if (!date) return app.$snack.error('Pass a valid date to momentHelpers.addToDate. date = ', date)
    if (!amount) return app.$snack.error('Pass a valid amount to momentHelpers.addToDate. amount = ', amount)
    if (!unit) return app.$snack.error('Pass a valid unit to momentHelpers.addToDate. unit = ', unit)
    return moment(date).add(amount, unit).toISOString()
  }

  // subtract from a date
  const subtractFromDate = (date, amount, unit) => {
    if (!date) return app.$snack.error('Pass a valid date to momentHelpers.subtractFromDate. date = ', date)
    if (!amount)
      return app.$snack.error('Pass a valid amount to momentHelpers.subtractFromDate. amount = ', amount)
    if (!unit) return app.$snack.error('Pass a valid unit to momentHelpers.subtractFromDate. unit = ', unit)
    return moment(date).subtract(amount, unit).toISOString()
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const momentHelpers = () => {
    return {
      getThisMonday,
      getThisTuesday,
      getThisWednesday,
      getLastSunday,
      currentWeek,
      getWeek,
      isMonday,
      isTuesday,
      isWednesday,
      isSunday,
      getMondayOfDate,
      getTuesdayOfDate,
      getWednesdayOfDate,
      getWednesdayBeforeDate,
      getMondayBeforeDate,
      getMondayAfterDate,
      getWednesdayAfterDate,
      getSundayOfDate,
      getSundayBeforeDate,
      getCalendarWeek,
      getRevenueWeek,
      getFinancialWeek,
      concordeWeekDisplay,
      weekCommencing,
      weekCommencingNewStarters,
      weekCommencingCTBReport,
      getFirstMondayAfterDate,
      addToDate,
      subtractFromDate
    }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('momentHelpers', momentHelpers()) // this.$momentHelpers
  inject('moment', moment) // this.$moment
}
