import _escaperegexp from 'lodash.escaperegexp'
export default ({ store, redirect, route, app }, inject) => {
  // routes that dont need auth
  const nonAuthedRoutes = [
    '/',
    '/welcome',
    '/sign-up',
    '/log-in',
    '/reset-password',
    '/help',
    '/authorisation-request/*',
    '/company',
    '/company/*',
    '/sign-up'
  ]

  // routes that will redirect to the home page if the user is authed and navigates to them.
  const redirectHomeRoutes = ['/', '/log-in', '/sign-up', '/reset-password', '/sign-up']

  // routes that are used while user account is being set up.
  const setUpAccountRoutes = ['/set-up-account', '/verify-email-address']

  // function to match route paths ('/authorisation-request/linkID1345')
  // to string patterns with wildcards ('/authorisation-request/*')
  const matchPath = (pattern, path) => {
    const hasWildcard = pattern.includes('*')
    if (!hasWildcard) {
      return pattern === path
    }
    pattern = _escaperegexp(pattern)
    pattern = pattern.split('/').join('\\/').split('\\*').join('[^\\/]*\\/?$') // make the regex for matching
    return path.match(pattern)
  }

  // function to take the $route as a param and return true if route
  // starts with one of the nonAuthedRoutes
  const isNonAuthedRoute = (r) => {
    return nonAuthedRoutes.map((x) => matchPath(x, r.path)).some((x) => x)
  }

  // function to take the $route as a param and return true if route
  // starts with one of the nonAuthedRoutes
  const isRedirectHomeRoute = (r) => {
    r = r || route
    return redirectHomeRoutes.map((x) => matchPath(x, r.path)).some((x) => x)
  }

  // function to take the $route as a param and return true if route
  // starts with one of the setUpAccountRoutes
  const isSetUpAccountRoute = (r) => {
    r = r || currentRoute()
    return setUpAccountRoutes.map((x) => matchPath(x, r.path)).some((x) => x)
  }

  // function to redirect the user to the relevant 'home' depending on their customClaims object on auth
  const redirectHome = async ({ route = null, claimsObject = null } = {}) => {
    try {
      route = route || store.getters['routes/current']
      claimsObject = claimsObject || (await app.$user.getCustomClaims())

      // if no user
      if (!claimsObject.userID) {
        return route.path === '/log-in' || redirect('/log-in')
      }
      // if user hasn't verified email
      // if (!claimsObject.email_verified) {
      //   return route.path === '/verify-email-address' || redirect('/verify-email-address')
      // }
      // if user is super admin
      if (claimsObject.superAdmin) {
        return route.path === '/admin' || redirect('/admin')
      }
      // if user is admin
      if (claimsObject.admin) {
        return route.path === '/admin' || redirect('/admin')
      }
      // if user is company
      if (claimsObject.company || claimsObject.companyUser) {
        return route.path === '/company/my-company' || redirect('/company/my-company')
      }
      // if user is candidate
      if (claimsObject.candidate) {
        return route.path === '/candidate/my-placements' || redirect('/candidate/my-placements')
      }
      // if user is authoriser
      if (claimsObject.authoriser) {
        return route.path === '/authoriser' || redirect('/authoriser')
      }
      // if all else fails
      return route.path === '/log-in' || redirect('/log-in')
    } catch (e) {
      console.error(e)
      return true
    }
  }

  // function to see if user has permission to access a certain route
  // takes in the route object that they are trying to access and the user's custom claims obj
  const userHasPermissionsForRoute = (route, claimsObject) => {
    // if the route doesn't need auth, just return true - all good!
    if (isNonAuthedRoute(route)) {
      return true
    }
    claimsObject = claimsObject || store.getters['user/claims']
    // if the user's email address isn't verified yet, then just send them to the email verification page.
    // if (!claimsObject.email_verified) {
    //   return redirect('/verify-email-address')
    // }
    // otherwise, lets check to see if the user's custom claims allows them access
    return (
      (route.path.startsWith('/admin') && claimsObject.superAdmin) ||
      (route.path.startsWith('/admin') && claimsObject.admin) ||
      (route.path.startsWith('/company') && (claimsObject.company || claimsObject.companyUser)) ||
      (route.path.startsWith('/candidate') && claimsObject.candidate) ||
      (route.path.startsWith('/authoriser') && claimsObject.authoriser)
    )
  }

  // function to get the current route from what it is set as in the store - more up to date
  // than using the
  const currentRoute = () => {
    return store.getters['routes/current']
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const routeHelper = () => {
    return {
      isNonAuthedRoute,
      isRedirectHomeRoute,
      redirectHome,
      userHasPermissionsForRoute,
      isSetUpAccountRoute,
      currentRoute
    }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('routeHelper', routeHelper()) // this.$routeHelper
}
