<template lang="html">
  <div>
    <v-dialog scrollable max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn icon :color="color" text v-on="on">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <json-viewer :value="value" :copyable="copyable" :expand-depth="expandDepth" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // value used for v-model
    value: {
      type: undefined,
      default: null
    },
    icon: {
      type: String,
      default: 'code'
    },
    color: {
      type: String,
      default: 'grey'
    },
    copyable: {
      type: Boolean,
      default: true
    },
    expandDepth: {
      type: Number,
      default: 5
    }
  }
}
</script>

<style lang="css" scoped></style>
