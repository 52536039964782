<template lang="html">
  <v-avatar :size="size" :color="colors.backgroundColor" :class="{ 'elevation-2': elevate }">
    <span v-if="initials" :class="`${colors.color}--text`" class="headline animated fadeIn">{{
      initials
    }}</span>
    <v-progress-circular v-else indeterminate size="40" color="accent lighten-3" />
  </v-avatar>
</template>

<script>
import toMaterialStyle from 'material-color-hash'
export default {
  props: {
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 50
    },
    elevate: Boolean
  },
  computed: {
    initials() {
      if (!this.firstName && !this.lastName) {
        return false
      }
      return (this.firstName || '~').toUpperCase()[0] + (this.lastName || '~').toUpperCase()[0]
    },
    colors() {
      return toMaterialStyle(this.firstName + this.lastName)
    }
  }
}
</script>

<style lang="css"></style>
