<template lang="html">
  <v-app light>
    <generalNav>
      <v-list :two-line="!isMd" class="pt-0">
        <v-list-item
          v-for="(item, i) in navItems"
          :key="i"
          nuxt
          :to="item.link"
          two-line
          active-class="primary white--text"
          :style="{ height: '60px' }"
          dense
          @click="item.link ? () => {} : doMethod(item.action)"
        >
          <v-list-item-content>
            <p class="mb-0" :class="{ headline: !isMd }">
              <v-icon v-if="!isMd && item.icon" class="pb-1 mr-2">
                {{ item.icon }}
              </v-icon>
              {{ item.title }}
            </p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </generalNav>

    <generalAppToolbar />

    <v-main>
      <!-- Page view -->
      <v-container class="pa-0">
        <nuxt />
      </v-container>
    </v-main>

    <!-- Snackbars -->
    <snackBars />

    <!-- here's a general confirmation dialog -->
    <confirmDialog />

    <!-- Here's the need help bottom sheet -->
    <needHelpSheet hide-bottom-bar />

    <!-- Here's the preloader -->
    <preLoader />

    <!-- Here's the more details fullscreen dialog -->
    <needMoreDetailsDialog />
  </v-app>
</template>

<script>
import needMoreDetailsDialog from '~/components/user/needMoreDetailsDialog'
import generalNav from '~/components/layout/generalNav'
import generalAppToolbar from '~/components/layout/generalAppToolbar'
import needHelpSheet from '~/components/layout/needHelpSheet'
import preLoader from '~/components/layout/preLoader'
import snackBars from '~/components/utils/snackBars'
import confirmDialog from '~/components/utils/confirmDialog'
export default {
  components: {
    preLoader,
    confirmDialog,
    needHelpSheet,
    snackBars,
    generalNav,
    generalAppToolbar,
    needMoreDetailsDialog
  },
  data: () => ({
    isHydrated: false,
    navItems: [
      { title: 'My Timesheets', link: '/candidate/my-timesheets' },
      { title: 'My Placements', link: '/candidate/my-placements' },
      { title: 'My Account', link: '/candidate/my-account' },
      { title: 'Help', icon: 'help_outline', action: 'launchHelpSheet' },
      { title: 'Logout', icon: 'power_settings_new', action: 'confirmLogout' }
    ]
  }),
  computed: {
    isMd() {
      // boolean to see if screensize is md or larger must be done after hydration in ssr
      return this.isHydrated ? this.$vuetify.breakpoint.mdAndUp : true
    }
  },
  mounted() {
    this.isHydrated = true
  },
  methods: {
    doMethod(name) {
      if (name) {
        return this[name]()
      } else {
        return this.$snack.error(`No method 'name' passed to doMethod()`)
      }
    },
    launchHelpSheet() {
      return this.$store.commit('utils/needHelpSheet', true)
    },
    confirmLogout() {
      return this.$dialog.confirm(this.logout, {
        title: 'Log out',
        text: 'Are you sure you want to log out?',
        buttons: {
          confirm: 'Yes',
          cancel: 'No'
        }
      })
    },
    async logout() {
      await this.$user.logout()
    }
  }
}
</script>

<style lang="scss">
.crisp-toolbar {
  &-logo {
    max-height: 48px;
  }
}
</style>
