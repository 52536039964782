<template lang="html">
  <client-only>
    <v-app>
      <generalAppToolbar hide-menu-icon>
        <template v-slot:leftEnd>
          <elasticAutoComplete />
        </template>
        <template v-slot:rightEnd>
          <accountCard :avatar-only="!isMd" />
        </template>
      </generalAppToolbar>

      <v-navigation-drawer
        v-model="nav"
        app
        expand-on-hover
        permanent
        clipped
        :mini-variant.sync="mini"
      >
        <template #prepend>
          <v-list>
            <v-list-item
              v-for="item in navItems"
              :key="item.title"
              link
              nav
              exact
              nuxt
              :to="item.link"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Management Dashboard -->
            <v-list-item
              v-if="userData && userData.managementUser"
              link
              nav
              exact
              nuxt
              to="/admin/management-dashboard"
            >
              <v-list-item-icon>
                <v-icon>mdi-monitor-dashboard</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Management Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Consultant View -->
            <v-list-item
              v-if="userData && userData.consultantUser"
              link
              nav
              exact
              nuxt
              to="/admin/consultant-view"
            >
              <v-list-item-icon>
                <v-icon>mdi-home-account</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Consultant</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Browse Links -->
            <v-list-group prepend-icon="mdi-table-eye" :value="expandBrowseLinks" nav dense>
              <template v-slot:activator>
                <v-list-item-title>Browse</v-list-item-title>
              </template>

              <v-list-item
                v-for="(item, i) in browseNavLinks"
                :key="i"
                link
                nav
                exact
                nuxt
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
              </v-list-item>

              <!-- CR Links -->
              <v-list-group :value="expandCrLinks" nav sub-group>
                <template v-slot:activator>
                  <v-list-item-title class="font-weight-light">CR</v-list-item-title>
                </template>

                <v-list-item
                  v-for="(item, i) in crNavLinks"
                  :key="i"
                  link
                  nav
                  exact
                  nuxt
                  :to="item.link"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
                </v-list-item>
              </v-list-group>

              <!-- CTB Links -->
              <v-list-group :value="expandCtbLinks" nav sub-group>
                <template v-slot:activator>
                  <v-list-item-title class="font-weight-light">CTB</v-list-item-title>
                </template>

                <v-list-item
                  v-for="(item, i) in ctbNavLinks"
                  :key="i"
                  link
                  nav
                  exact
                  nuxt
                  :to="item.link"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list-group>

            <!-- Beta Links -->
            <v-list-group
              v-if="isBetaUser"
              prepend-icon="mdi-traffic-cone"
              :value="expandBetaLinks"
              nav
            >
              <template v-slot:activator>
                <v-list-item-title>Beta</v-list-item-title>
              </template>

              <v-list-item
                v-for="(item, i) in betaNavItems"
                :key="i"
                link
                nav
                exact
                nuxt
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
        </template>

        <!-- Navigation Footer -->
        <template #append>
          <v-list dense>
            <v-divider></v-divider>
            <!-- Reports Links -->
            <v-list-group prepend-icon="mdi-file-chart" :value="expandReportsLinks" nav>
              <template v-slot:activator>
                <v-list-item-title>Reports</v-list-item-title>
              </template>

              <v-list-item
                v-for="(item, i) in reportsNavItems"
                :key="i"
                link
                dense
                exact
                nuxt
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>

            <!-- Developer Links -->
            <v-list-group v-if="isDev" prepend-icon="mdi-code-tags" :value="expandDevLinks" nav>
              <template v-slot:activator>
                <v-list-item-title>Developer</v-list-item-title>
              </template>

              <v-list-item
                v-for="(item, i) in superAdminNavItems"
                :key="i"
                link
                nav
                dense
                exact
                nuxt
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-divider></v-divider>
            <!-- Super Admin Links -->
            <v-list-group
              v-if="isSuperAdmin"
              prepend-icon="mdi-database-settings"
              :value="expandSuperAdminLinks"
              nav
            >
              <template v-slot:activator>
                <v-list-item-title>Manage</v-list-item-title>
              </template>

              <v-list-item
                v-for="(item, i) in superAdminNavItems"
                :key="i"
                link
                nav
                dense
                exact
                nuxt
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-divider></v-divider>
            <!-- General Settings Links -->
            <v-list-group
              prepend-icon="mdi-dots-horizontal-circle"
              :value="expandSettingsLinks"
              nav
            >
              <template v-slot:activator>
                <v-list-item-title>More</v-list-item-title>
              </template>

              <v-list-item
                v-for="(item, i) in settingsNavItems"
                :key="i"
                link
                nav
                dense
                exact
                nuxt
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-light" v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
        </template>
      </v-navigation-drawer>

      <!-- <generalNav :miniable="isMd">
      <v-list class="pt-0">
        <template v-for="(item, i) in navItems">
          <v-subheader v-if="item.header" :key="i">
            {{ item.header }}
          </v-subheader>

          <v-tooltip :key="`list-title-${i}`" :disabled="!mini" right>
            <template v-slot:activator="{ on }">
              <v-list-item
                exact
                nuxt
                :to="item.link"
                two-line
                active-class="secondary accent--text"
                dense
                :style="{ height: '60px' }"
                v-on="on"
              >
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </template>
      </v-list>
      </generalNav>-->

      <v-main>
        <!-- Page view -->
        <!-- <v-container class="pa-0">
        <nuxt />
        </v-container>-->

        <nuxt />
      </v-main>

      <!-- Snackbars -->
      <snackBars />

      <!-- here's a general confirmation dialog -->
      <confirmDialog />

      <!-- Here's the need help bottom sheet -->
      <!-- <needHelpSheet/> -->

      <!-- Here's the preloader -->
      <preLoader />

      <!-- Here's the more details fullscreen dialog -->
      <needMoreDetailsDialog />

      <v-footer class="px-3 py-1">
        <div class="caption ml-16">{{ userData && userData.footerMessage }}</div>

        <v-spacer />
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon color="grey" dark v-on="on" @click="gotoBugReport()">mdi-lifebuoy</v-icon>
            </template>
            <span>Contact Carepenny Support</span>
          </v-tooltip>

          <v-chip
            v-if="userData && userData.userType == 'superAdmin'"
            label
            small
            color="red"
            text-color="white"
          >Super Admin</v-chip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-chip label small v-on="on">&copy; Carepenny Ltd {{ new Date().getFullYear() }}</v-chip>
            </template>
            <span>
              This is CRISP v3.0-{{ buildNumber }} ({{ version }}), Environment: {{ environmentName }}, Node
              Env: {{ nodeEnv }}, Base URL: {{ baseURL }} - Developed by Carepenny
            </span>
          </v-tooltip>
        </div>
      </v-footer>
    </v-app>
  </client-only>
</template>

<script>
import needMoreDetailsDialog from '~/components/user/needMoreDetailsDialog'
import accountCard from '~/components/user/accountCard'
// import generalNav from '~/components/layout/generalNav'
import generalAppToolbar from '~/components/layout/generalAppToolbar'
import preLoader from '~/components/layout/preLoader'
import snackBars from '~/components/utils/snackBars'
import confirmDialog from '~/components/utils/confirmDialog'
import elasticAutoComplete from '~/components/globalSearch/globalSearchField'
import { mapGetters } from 'vuex'
export default {
  components: {
    preLoader,
    confirmDialog,
    snackBars,
    generalAppToolbar,
    accountCard,
    needMoreDetailsDialog,
    elasticAutoComplete
  },
  data: () => ({
    nav: true,
    expandSuperAdminLinks: false,
    expandSettingsLinks: false,
    expandDevLinks: false,
    expandReportsLinks: false,
    expandBrowseLinks: false,
    expandCrLinks: false,
    expandCtbLinks: false,
    expandBetaLinks: false,
    isHydrated: false,
    navItems: [{ title: 'Dashboard', icon: 'dashboard', link: '/admin' },
    {
      title: 'My Timesheets',
      icon: 'mdi-calendar-star',
      link: '/admin/timesheets/by-consultant'
    }
    ],
    browseNavLinks: [

      { title: 'Candidates', icon: 'person', link: '/admin/users/candidates' },
      { title: 'Companies', icon: 'business', link: '/admin/users/companies' },

      { title: 'Timesheets', icon: 'date_range', link: '/admin/timesheets' },
      {
        title: 'Placements',
        icon: 'assignment_ind',
        link: '/admin/placements'
      }
    ],
    ctbNavLinks: [
      {
        title: 'CTB Timesheets',
        icon: 'date_range',
        link: '/admin/timesheets/ctb'
      },
      {
        title: 'CTB Placements',
        icon: 'assignment_ind',
        link: '/admin/placements/ctb'
      }
    ],
    crNavLinks: [
      {
        title: 'CR Timesheets',
        icon: 'date_range',
        link: '/admin/timesheets/cr'
      },
      {
        title: 'CR Placements',
        icon: 'assignment_ind',
        link: '/admin/placements/cr'
      }
    ],
    betaNavItems: [
      {
        title: 'Management View',
        icon: 'mdi-home-analytics',
        link: '/admin/management-dashboard'
      }
    ],
    reportsNavItems: [
      {
        title: 'Reports',
        icon: 'multiline_chart',
        link: '/admin/reports'
      },
      {
        title: 'Company Users',
        icon: 'mdi-briefcase-account',
        link: '/admin/users/companies/company-users'
      },
      {
        title: 'AWR Alerts',
        icon: 'mdi-account-alert',
        link: '/admin/placements/awr-alerts'
      },
      {
        title: 'Queried Timesheets',
        icon: 'mdi-file-alert',
        link: '/admin/placements/queried-timesheets'
      },
      {
        title: 'Legacy Reports',
        icon: 'mdi-chart-timeline',
        link: '/admin/timesheets/reports'
      }
    ],
    settingsNavItems: [
      {
        title: 'Integration Tools',
        icon: 'mdi-database-sync-outline',
        link: '/admin/tools/vincere-tools'
      },
      {
        title: 'Live History',
        icon: 'history',
        link: '/admin/tools/live-history'
      },
      {
        title: 'Email Tracking',
        icon: 'mdi-email-search',
        link: '/admin/tools/emails'
      },
      {
        title: 'Placements Inbox',
        icon: 'move_to_inbox',
        link: '/admin/placements/inbox'
      },
      {
        title: 'System Settings',
        icon: 'settings',
        link: '/admin/system-settings'
      }
    ],
    superAdminNavItems: [
      {
        title: 'Admin Users',
        icon: 'verified_user',
        link: '/admin/users/admins'
      },

      {
        title: 'Generated Timesheets',
        icon: 'today',
        link: '/admin/tools/timesheets-cron'
      },
      { title: 'Umbrella Companies', icon: 'beach_access', link: '/admin/users/umbrella-companies' },
      {
        title: 'Released Candidates',
        icon: 'email',
        link: '/admin/released-candidates'
      },
      {
        title: 'CSV Import Tool',
        icon: 'mdi-file-delimited-outline',
        link: '/admin/tools/csv-import'
      }
    ],
    appEngineVersion: process.env.appEngineVersion,
    environmentName: process.env.environmentName,
    nodeEnv: process.env.NODE_ENV,
    baseURL: process.env.appBaseURL, // Get enviroment vars
    version: process.env.version,
    buildNumber: process.env.buildNumber
  }),
  computed: {
    ...mapGetters({
      claims: 'user/claims',
      userData: 'user/userData',
      adminID: 'admin/id',
      candidateID: 'candidate/id'
    }),
    isSuperAdmin() {
      const { claims } = this
      return claims && claims.superAdmin
    },
    isAdmin() {
      const { claims } = this
      return claims && claims.admin
    },
    mini: {
      get() {
        return this.$store.getters['layout/miniDrawer']
      },
      set(v) {
        return this.$store.commit('layout/miniDrawer', v)
      }
    },
    isMd() {
      // boolean to see if screensize is md or larger must be done after hydration in ssr
      return this.isHydrated ? this.$vuetify.breakpoint.mdAndUp : true
    },
    // baseURL () {
    //   return process.env.appBaseURL
    // }
    isDev() {
      const { dev } = this.$route.query
      return dev || false
    },
    isBetaUser() {
      const { userData } = this
      if (userData && userData.betaAccess) {
        return true
      }
      return false
    },
    isConsultantUser() {
      const { userData } = this
      if (userData && userData.consultantUser) {
        return true
      }
      return false
    },
    isManagementUser() {
      const { userData } = this
      if (userData && userData.managementUser) {
        return true
      }
      return false
    }
  },
  mounted() {
    this.isHydrated = true
  },
  created() {
    // if (this.isSuperAdmin) {
    //   this.navItems.push(
    //     {
    //       title: 'Timesheets Generated by Cron',
    //       icon: 'today',
    //       link: '/admin/tools/timesheets-cron'
    //     },
    //     {
    //       header: 'Administration Tools',
    //       title: 'Manage Concorde Users',
    //       icon: 'verified_user',
    //       link: '/admin/users/admins'
    //     },
    //     { title: 'Manage Umbrella Companies', icon: 'beach_access', link: '/admin/users/umbrella-companies' },
    //     {
    //       title: 'Manage Released Candidates',
    //       icon: 'email',
    //       link: '/admin/released-candidates'
    //     },
    //     {
    //       title: 'System Settings',
    //       icon: 'settings',
    //       link: '/admin/system-settings'
    //     },
    //     {
    //       title: 'CSV Import Tool',
    //       icon: 'mdi-file-delimited-outline',
    //       link: '/admin/tools/csv-import'
    //     }
    //   )
    // }
  },
  methods: {
    doMethod(name) {
      if (name) {
        return this[name]()
      } else {
        return this.$snack.error(`No method 'name' passed to doMethod()`)
      }
    },
    testSnack() {
      return this.$snack.info('User: ' + this.userData)
    },
    gotoBugReport() {
      // let reporturl =
      //   'https://concordeonline.co.uk/report-crisp-issue?fname=' +
      //   this.userData.firstName +
      //   '&lname=' +
      //   this.userData.lastName +
      //   '&email=' +
      //   this.userData.email +
      //   '&build=' +
      //   this.buildNumber
      const reporturl = "https://support.carepenny.com/"
      window.window.open(reporturl)
    },
    logout() {
      return this.$user.logout()
    }
  }
}
</script>

<style lang="scss">
.crisp-toolbar {
  &-logo {
    max-height: 48px;
  }
}
</style>
