<template lang="html">
  <v-dialog
    v-model="launch"
    fullscreen
    transition="dialog-bottom-transition"
    hide-overlay
    :style="{
      marginLeft: !noSideDrawer && $vuetify.breakpoint.mdAndUp ? '300px' : '0px'
    }"
  >
    <template v-if="!hideBottomBar" v-slot:activator="{ on }">
      <v-btn slot="activator" text color="accent" class="unTransformText" v-on="on">
        Need help? Contact us
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="accent" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
        <v-row class="justify-center">
          <v-col cols="10" class="text-left">
            <h2 class="white--text">
              Contact Us
            </h2>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn class="ma-0" color="white" icon text @click="launch = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="overflow-y-auto crisp-need-help-sheet-max-height">
        <v-row class="justify-center">
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>
                <v-col cols="12">
                  <p>
                    What is your enquiry regarding?
                  </p>
                  <v-select v-model="selectedConcordeDepList" :items="concordeDepList" solo />
                </v-col>
              </v-card-title>
              <v-card-text>
                <template v-if="selectedConcordeDepList">
                  <v-col v-for="(detail, i) in contactDetails" :key="i" cols="12">
                    <v-btn
                      :href="detail.link[selectedConcordeDepList] || detail.link"
                      target="_blank"
                      class="unTransformText title ml-0"
                      color="accent"
                      text
                    >
                      <v-icon left>
                        {{ detail.icon }}
                      </v-icon>
                      {{ detail.text[selectedConcordeDepList] || detail.text }}
                    </v-btn>
                  </v-col>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="text-center">
            <p>
              The Concorde Group team is available from 8.30am to 5.30pm Mon-Fri to assist you.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    noSideDrawer: Boolean,
    hideBottomBar: Boolean
  },
  data: () => ({
    selectedConcordeDepList: 'cr',
    concordeDepList: [
      {
        text: 'Concorde Construction',
        value: 'cc'
      },
      {
        text: 'Concorde Recruitment',
        value: 'cr'
      },
      {
        text: 'Concorde Teaching Bank',
        value: 'ctb'
      },
      {
        text: 'Payroll / Accounts',
        value: 'pa'
      },
      {
        text: 'Something else',
        value: 'se'
      }
    ],
    contactDetails: [
      {
        icon: 'phone',
        text: {
          cc: '01872 262828',
          cr: '01872 262828',
          ctb: '01872 262033',
          pa: '01872 262828',
          se: '01872 262828'
        },
        link: {
          cc: 'tel:+441872262828',
          cr: 'tel:+44187226282',
          ctb: 'tel:+441872262033',
          pa: 'tel:+44187226282',
          se: 'tel:+44187226282'
        }
      },
      {
        icon: 'mail',
        text: {
          cc: 'concorde.construct@cornwall.ac.uk',
          cr: 'crt@cornwall.ac.uk',
          ctb: 'ctbt@cornwall.ac.uk',
          pa: 'crt@cornwall.ac.uk',
          se: 'crt@cornwall.ac.uk'
        },
        link: {
          cc: 'mailto:concorde.construct@cornwall.ac.uk',
          cr: 'mailto:crt@cornwall.ac.uk',
          ctb: 'mailto:ctbt@cornwall.ac.uk',
          pa: 'mailto:crt@cornwall.ac.uk',
          se: 'mailto:crt@cornwall.ac.uk'
        }
      },
      {
        icon: 'location_on',
        text: 'Chiltern House, Truro, TR1 2JL',
        link: 'https://goo.gl/maps/Hv7QWAF2oSu'
      },
      {
        icon: 'alternate_email',
        text: 'concorde-group.co.uk',
        link: 'mailto:concorde-group.co.uk'
      }
    ]
  }),
  computed: {
    launch: {
      get() {
        return this.$store.getters['utils/needHelpSheet']
      },
      set(v) {
        return this.$store.commit('utils/needHelpSheet', v)
      }
    }
  }
}
</script>

<style lang="css">
.crisp-need-help-sheet-max-height {
  max-height: calc(100vh - 120px);
}
</style>
