export const state = () => ({
  timesheetStatuses: undefined
})

export const mutations = {
  timesheetStatuses(state, data) {
    state.timesheetStatuses = data
  }
}

export const actions = {
  async watchTimesheetStatuses({ commit }, { startDate }) {
    const dbRef = this.$db.doc(`stats/timesheets/statuses/${startDate}`)
    this.$watchers.set('stats/timesheetStatuses', dbRef, (result) => {
      const data = result.exists ? this.$dbHelpers.extractDataFrom(result) : null
      commit('timesheetStatuses', data)
    })
  },
  stopWatchingTimesheetStatuses() {
    this.$watchers.stop('stats/timesheetStatuses')
  }
}

export const getters = {
  timesheetStatuses: (state) => state.timesheetStatuses
}
