/* Functions for registering and stopping watchers for the database.
 **
 ** All watchers should be registered to a named key in the 'watchers' object
 ** This can be done by using the $watchers.set('keyString', dbReference, returnFunction) function
 **
 ** Stop a watcher by using the $watchers.stop('keyString') function
 **
 ** Functions return a promise and throw an error and are available in
 ** the app context as this.$watchers
 */

export default ({ app }, inject) => {
  // create an empty object to contain watchers
  const watcherObj = {}

  /**
   * $watcher.set - Set up a watcher for a doc or collection in the database
   * Uses firestore onSnapshot() function
   *
   * @param {type} objKey Name of the watcher. Must be uniqe. Used to stop watcher
   * @param {type} dbRef  Firestore document or collection reference. eg: this.$db.doc('test/something')
   * @param {type} func   The function to invoke on a db update event. Passes the database onSnapshot result
   *
   * @return {type} returns the objKey passed in
   */
  const set = (objKey, dbRef, func, errFunc) => {
    if (!errFunc) {
      errFunc = (err) => {
        app.$handleError(err)
      }
    }
    watcherObj[objKey] = {
      setTimestamp: new Date().toISOString(),
      status: 'active',
      stopTimestamp: null,
      watcher: dbRef.onSnapshot(func, errFunc)
    }
    return objKey
  }

  /**
   * $watcher.stop - Stops a watcher by calling it as a function.
   *
   * @param {type} objKey The unique name of the watcher, which is it's object key defined when set.
   *
   * @return {type} Returns nothing as not neeeded.
   */
  const stop = (objKey) => {
    if (watcherObj[objKey] && watcherObj[objKey].watcher) {
      watcherObj[objKey].watcher()
      watcherObj[objKey] = {
        ...watcherObj[objKey],
        status: 'disabled',
        stopTimestamp: new Date().toISOString(),
        watcher: null
      }
    } else {
      console.error(`$watcher.stop(): objKey '${objKey}' does not exist on the watchers object.`)
    }
  }

  /**
   * $watcher.stopAll - Stop all currently active watchers for the app.
   *
   * @return {type} Returns nothing as not neeeded.
   */
  const stopAll = () => {
    for (const key in watcherObj) {
      if (watcherObj[key].status === 'active') {
        stop(key)
      }
    }
  }

  /**
   * $watcher.list - Lists all the watchers currently active.
   *
   * @param {object} [returnArray=Boolean] Takes one param which is an object
   * Set { returnArray: true } to return an array rather than the default object of active watchers.
   *
   * @return {object} Returns either an object (by default) or array of currently active watchers.
   */
  const list = ({ returnArray = false } = {}) => {
    if (!returnArray) return watcherObj
    const arr = []
    for (const key in watcherObj) {
      arr.push({ key, ...watcherObj[key] })
    }
    return arr
  }

  /*
   * Check if a watcher key exists
   */
  const exists = (objKey) => {
    return Boolean(watcherObj[objKey])
  }

  /*
   * Check if a watcher key exists
   */
  const isActive = (objKey) => {
    return Boolean(watcherObj[objKey] && watcherObj[objKey].status === 'active')
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const dbWatcherHelper = () => {
    return { set, stop, stopAll, list, exists, isActive }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('watchers', dbWatcherHelper()) // this.$watchers
}
