<template lang="html">
  <v-fade-transition>
    <div v-if="!isHydrated" class="crisp-preLoader">
      <v-row class="justify-center fullHeight crisp-preLoader-layout">
        <v-col cols="7" sm="5" md="2" class="animated fadeIn text-center">
          <div class="crisp-preLoader-logo">
            <img src="/concorde-logo-new-color.png" alt="" class="mb-4" />
          </div>
          <v-progress-circular indeterminate size="50" color="accent" />
        </v-col>
      </v-row>
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  data: () => ({
    isHydrated: false
  }),
  mounted() {
    setTimeout(() => {
      this.isHydrated = true
    }, 600)
  }
}
</script>

<style lang="scss">
.crisp-preLoader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f3f3f3;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;

  &-layout {
    margin-top: 20vh;
  }
  &-logo {
    max-width: 280px;
    margin: 0 auto;
  }
}
</style>
