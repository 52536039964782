export const state = () => ({
  snack: {
    info: { text: null, active: false },
    loading: { text: null, active: false },
    error: { text: null, active: false }
  }
})

export const mutations = {
  setSnackText(state, { text, type = 'info' }) {
    state.snack[type].text = text
  },
  toggleSnack(state, { type = 'info', value = undefined }) {
    state.snack[type].active = value === undefined ? !state.snack[type].active : value
  }
}

export const getters = {
  snacks: (state) => state.snack,
  info: (state) => state.snack.info,
  loading: (state) => state.snack.loading,
  error: (state) => state.snack.error
}
