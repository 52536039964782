export default ({ app }, inject) => {
  // take an array of 'days' (see timesheet schema) and return the total
  // number of hours worked.
  const calculateTotalHours = (days) => {
    // console.log(JSON.stringify(days, null, 2))
    if (!days || !days.length) {
      return 0
    }
    // filter only the days that have some time added
    const dayTotals = days.map((day) => {
      const { startTime, endTime, lunchAndBreaks, overtime, fullDay } = day
      const dayOvertime = {
        hours: Math.floor((overtime || 0) / 60),
        minutes: (overtime || 0) % 60
      }
      const dayLunchAndBreaks = {
        hours: Math.floor((lunchAndBreaks || 0) / 60),
        minutes: (lunchAndBreaks || 0) % 60
      }
      const workMins = calcTotalMins({ startTime, endTime })
      const totalMins = calcTotalMins({
        startTime,
        endTime,
        lunchAndBreaks,
        overtime,
        fullDay
      })
      const res = {
        overtime: dayOvertime,
        lunchAndBreaks: dayLunchAndBreaks,
        work: {
          hours: Math.floor((workMins || 0) / 60),
          minutes: (workMins || 0) % 60
        },
        totals: {
          hours: Math.floor((totalMins || 0) / 60),
          minutes: (totalMins || 0) % 60
        },
        minutes: totalMins
      }
      if (fullDay) {
        res.fullDay = true
      }
      return res
    })
    const allTotals = dayTotals.reduce(
      (acc, cur) => {
        if (cur.fullDay) {
          acc.fullDays += 1
          acc.totals.days += 1
        }
        acc.overtime.hours += cur.overtime ? cur.overtime.hours : 0
        acc.overtime.minutes += cur.overtime ? cur.overtime.minutes : 0
        acc.lunchAndBreaks.hours += cur.lunchAndBreaks ? cur.lunchAndBreaks.hours : 0
        acc.lunchAndBreaks.minutes += cur.lunchAndBreaks ? cur.lunchAndBreaks.minutes : 0
        acc.work.hours += cur.work ? cur.work.hours : 0
        acc.work.minutes += cur.work ? cur.work.minutes : 0
        acc.totals.hours += cur.totals ? cur.totals.hours : 0
        acc.totals.minutes += cur.totals ? cur.totals.minutes : 0
        acc.minutes += cur.minutes || 0
        // check to see if any minutes are above 59 and add 1 to hours if so
        if (acc.overtime.minutes > 59) {
          acc.overtime.hours += 1
          acc.overtime.minutes = acc.overtime.minutes % 60
        }
        if (acc.lunchAndBreaks.minutes > 59) {
          acc.lunchAndBreaks.hours += 1
          acc.lunchAndBreaks.minutes = acc.lunchAndBreaks.minutes % 60
        }
        if (acc.work.minutes > 59) {
          acc.work.hours += 1
          acc.work.minutes = acc.work.minutes % 60
        }
        if (acc.totals.minutes > 59) {
          acc.totals.hours += 1
          acc.totals.minutes = acc.totals.minutes % 60
        }
        return acc
      },
      {
        fullDays: 0,
        overtime: {
          hours: 0,
          minutes: 0
        },
        lunchAndBreaks: {
          hours: 0,
          minutes: 0
        },
        work: { hours: 0, minutes: 0 },
        totals: { days: 0, hours: 0, minutes: 0 },
        minutes: 0
      }
    )
    const prettyTotal = `${
      allTotals.totals.days
        ? allTotals.totals.days + ' ' + (allTotals.totals.days === 1 ? 'day' : 'days')
        : ''
    } ${allTotals.totals.hours} ${allTotals.totals.hours === 1 ? 'hour' : 'hours'} ${
      allTotals.totals.minutes
    } ${allTotals.totals.minutes === 1 ? 'min' : 'mins'}`.trim()
    return {
      dayTotals,
      allTotals,
      prettyTotal
    }
  }

  const calcTotalMins = ({ startTime, endTime, lunchAndBreaks, overtime, fullDay }) => {
    if (!startTime || !endTime || fullDay) {
      return 0 + (overtime || 0)
    }
    const start = app.$moment(startTime)
    const end = app.$moment(endTime)
    const minutes = end.diff(start, 'minutes') - (lunchAndBreaks || 0) + (overtime || 0)
    return minutes
  }
  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const timesheetHelper = () => {
    return { calculateTotalHours }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('timesheets', timesheetHelper()) // this.$timesheets
}
