// import and register your global vue components here
import Vue from 'vue'

// Components to import
import fullScreenImg from '~/components/layout/fullScreenImg.vue'
import ScrollContainer from '~/components/utils/ScrollContainer'

// Register components on the vue instance
Vue.component('fullScreenImg', fullScreenImg)
Vue.component('ScrollContainer', ScrollContainer)
