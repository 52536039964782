<template lang="html">
  <v-autocomplete
    v-model="localVal"
    v-bind="autoCompleteProps"
    :item="items"
    :label="label"
    :loading="loading"
    prepend-inner-icon="mdi-search-web"
    placeholder="Search all records"
    solo-inverted
    flat
    dense
    hide-details
    class="mt-1"
    dark
  >
    <!-- <template v-slot:append-outer>
      <v-icon
        :color="rotating ? 'grey' : 'primary'"
        class="standard-easing"
        :class="{ rotating: rotating }"
        @click="loading ? '' : getResults()"
      >refresh</v-icon>
    </template>-->

    <template v-slot:no-data>
      <v-chip class="ml-4">
        <v-avatar left>
          <v-icon small red>mdi-domain-off</v-icon>
        </v-avatar>Global search is not enabled for your organisation.
      </v-chip>
      <!-- <v-list-item-content>Global search is not enabled for your organisation.</v-list-item-content> -->
    </template>

    <template v-slot:item="props">
      <slot name="item" :item="props.item">
        <v-list-item-content>{{ props.item[(autoCompleteProps && autoCompleteProps['item-text']) || props.item] || props.item }}</v-list-item-content>
      </slot>
    </template>
    <template v-slot:selection="props">
      <slot
        name="selection"
        :item="props.item"
        :index="props.index"
        :selected="props.selected"
        :disabled="props.disabled"
      >
        <v-list-item-content>{{ props.item[(autoCompleteProps && autoCompleteProps['item-text']) || props.item] || props.item }}</v-list-item-content>
      </slot>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: {
      required: false, // for v-model only
      type: undefined,
      default: null
    },
    // searchEndpoint: {
    //   required: true,
    //   type: String
    // },
    refreshable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-search'
    },
    autoCompleteProps: {
      type: Object,
      default: () => ({})
    },
    stopInitialGet: Boolean, // stops the mounted getResults() hook
    logItems: Boolean // logs the items on getResults() for debugging
  },
  data: () => ({
    loading: false,
    rotating: false,
    items: []
  }),
  computed: {
    localVal: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('update', v)
      }
    }
  },
  watch: {
    loading(v) {
      if (v) {
        this.rotating = v
      } else {
        setTimeout(() => {
          this.rotating = v
        }, 1000)
      }
    }
  },
  mounted() {
    if (!this.stopInitialGet) {
      this.getResults()
    }
  },
  methods: {
    async getResults() {
      try {
        // if (!this.searchEndpoint) {
        //   throw new Error('elasticAutoComplete: no searchEndpoint')
        // }
        this.loading = true
        // const { data } = await this.$axios.get(this.searchEndpoint)
        // this.items = data.results
        this.items = {
          "item-text": 'Coming soon'
        }


        this.loading = false
      } catch (e) {
        this.loading = false
        this.$handleError(e)
      }
    }
  }
}
</script>

<!-- <style lang="css">
@keyframes rotatin
@keyframes rotating {
  from {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
} -->
