// Function to handle errors from the app.
// Should be used for all error handling as is handles mapping of common codes,
// errors from axios that are nested in response{} and null errors.

// Map some error codes to some more friendly user messages.
const errorMap = {
  // e.code: 'Message to $snack()'
  'auth/weak-password': 'Try making a longer password',
  'auth/email-already-in-use': 'That email address already has an account!',
  'auth/wrong-password': 'Oops! Looks like the wrong password, please try again.',
  'auth/user-not-found': `We couldn't find your email address!`
}

// error codes to NOT snack
const noSnack = ['dialog/userCancel']

// Function to handle the mapping of error codes.
const mapErrorCode = (e) => {
  return errorMap[(e && e.code) || (e.response && e.response.data && e.response.data.code) || ''] || false
}

// Function to check if we should snack the error based on the noSnack array
const dontSnack = (e) => {
  return noSnack.includes(e && e.code)
}

export default ({ app }, inject) => {
  const handleError = (e) => {
    e = e || 'handleError() function invoked without passing first arg.'
    console.error(e)
    // Connect to sentry if not in dev
    if (!process.env.dev && app.$sentry) {
      app.$sentry.captureException(e)
    }
    // check if we should skip snacking the error. Usually we will want to snack it.
    const message = mapErrorCode(e) || (e.response && e.response.data) || e.message || e
    if (dontSnack(e)) {
      return message
    }
    app.$snack.error(message)
    return message
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('handleError', handleError) // this.$handleError
}
