<template lang="html">
  <v-dialog v-model="toggle" max-width="400" persistent>
    <v-card>
      <v-card-title class="primary white--text">
        <v-row no-gutters class="justify-center">
          <v-col cols="12" class="text-left">
            <h3>{{ dialog.title }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="justify-center">
          <v-col cols="12" class="text-left subtitle-2 black--text">
            <p>{{ dialog.text }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="dialog.buttons && dialog.buttons.cancel" text @click.stop="toggle = false">
          {{ (dialog.buttons && dialog.buttons.cancel) || 'Cancel' }}
        </v-btn>
        <v-spacer />
        <v-btn :loading="loading" color="primary" @click.stop="doAction()">
          {{ (dialog.buttons && dialog.buttons.confirm) || 'Confirm' }}
        </v-btn>
        <v-spacer v-if="!dialog.buttons.cancel" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialogs/confirm'
    }),
    toggle: {
      get() {
        return this.$store.getters['dialogs/confirm'].toggle
      },
      set(val) {
        // should only fire on false
        if (!val) {
          return this.$store.commit('dialogs/clearConfirmDialog')
        }
      }
    }
  },
  methods: {
    async doAction() {
      try {
        this.loading = true
        await this.$store.dispatch('dialogs/completeConfirmAction')
        this.loading = false
      } catch (e) {
        this.loading = false
        this.toggle = false
      }
    }
  }
}
</script>

<style lang="css"></style>
