export default (context, inject) => {
  const itrisPlacementSchema = () => {
    return {
      Placement_Business_Type: 'PAYE',
      Department_name: 'TEST',
      Applicant_email_address: '',
      Applicant_first_name: '',
      Applicant_last_name: '',
      Applicant_phone_number: '',
      NI_Number: '',
      Job_title: '',
      Job_description: 'TEST JOB 1',
      Job_Id: 'HQXXX',
      Placement_Hours_of_Work: '',
      Start_date: '04-Feb-2019',
      End_date: '08-Feb-2019',
      Pay_rate: '9.50',
      Charge_rate: '10.50',
      Holiday_rate: '2.40',
      Charge_Interval: 0,
      Overtime_rates: '',
      Placement_Id: '',
      Company_Id: '',
      Company_name: '',
      Contact_email_address: 'me+auth1@jontyb.co.uk',
      Contact_first_name: '',
      Contact_last_name: '',
      Contact_phone_number: '',
      Contact_Id: '',
      Payroll_Reference: '',
      Request_Reason: 'TEST New Placement',
      Notes: 'This is a test placement'
    }
  }
  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const placementHelper = () => {
    return { itrisPlacementSchema }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('placements', placementHelper()) // this.$placements
}
