export const state = () => ({
  drawer: null,
  miniDrawer: false
})

export const mutations = {
  drawer(state, data) {
    state.drawer = data
  },
  miniDrawer(state, data) {
    state.miniDrawer = data
  }
}

export const getters = {
  drawer: (state) => state.drawer,
  miniDrawer: (state) => state.miniDrawer
}
