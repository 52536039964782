import { render, staticRenderFns } from "./candidate.vue?vue&type=template&id=77a22639&lang=html&"
import script from "./candidate.vue?vue&type=script&lang=js&"
export * from "./candidate.vue?vue&type=script&lang=js&"
import style0 from "./candidate.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/@nuxtjs/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VContainer,VIcon,VList,VListItem,VListItemContent,VMain})
