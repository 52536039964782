export const state = () => ({
  tableStates: {}
})

export const mutations = {
  tableStates(state, { id, persist }) {
    state.tableStates[id] = persist
  }
}

export const getters = {
  tableStates: (state) => state.tableStates
}
