// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import config from '../nuxt.config.js'

// Get the firebase config from the config file.
const { fireBaseConfig } = config
// Set up the firebase app. If an instance of firebase is already running, use that.
const firebaseApp = !firebase.apps.length ? firebase.initializeApp(fireBaseConfig) : firebase.app()

export default (context, inject) => {
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('firebase', firebase) // this.$firebase
  inject('firebaseApp', firebaseApp) // this.$firebaseApp
  inject('auth', firebaseApp.auth()) // this.$auth
  inject('db', firebaseApp.firestore()) // this.$db
}
