<template lang="html">
  <!-- /* eslint-disable vue/no-use-v-if-with-v-for */ -->
  <v-dialog v-model="dialog" fullscreen persistent :overlay="false" class="accent">
    <v-card class="accent">
      <v-card-text class="accent overflow-y-auto" style="height: 100vh;">
        <v-row class="justify-center">
          <v-col cols="12" sm="10" md="7" lg="5">
            <v-row class="justify-left">
              <v-col cols="1" class="text-left">
                <img src="/concorde-logo-new-white.png" alt="" />
              </v-col>
              <v-col cols="11" class="text-left">
                <h2 class="white--text">Your Account</h2>
              </v-col>
            </v-row>
            <v-row class="accent fill-height" align-center>
              <v-slide-x-transition mode="out-in">
                <v-col v-if="step === 0" key="start" cols="12" class="">
                  <h2 class="white--text">We need to confirm some of your Concorde Online account details</h2>
                  <p class="white--text mt-4">
                    So that we can ensure our payroll records are accurate, we need to confirm some of your
                    account details.
                  </p>

                  <v-alert type="info" border="top" colored-border>
                    <p class="overline">Done this before?</p>
                    You may need to re-enter some details you have entered before. This is so that we can
                    ensure our records are up to date.
                  </v-alert>

                  <v-alert type="info" border="top" colored-border icon="help">
                    <p class="overline">Security concerns?</p>
                    If you would like to confirm that it is really Concorde is asking for these details please
                    don't hesitate to call us.
                  </v-alert>
                  <v-btn
                    :dark="!userData || !userData.accessEmail"
                    :disabled="!userData || !userData.accessEmail"
                    class="mt-4"
                    color="primary"
                    @click="step = 1"
                  >
                    {{ userData && userData.accessEmail ? 'Continue' : 'Loading...' }}
                  </v-btn>
                  <p class="white--text pointer mt-12 caption" @click="logout()">LOG OUT</p>
                </v-col>
                <v-col v-else key="questions">
                  <v-slide-x-transition mode="out-in">
                    <v-row v-for="(question, key) in questions" :key="`${key}`" row wrap>
                      <v-col
                        v-if="currentAnswerNeeded.dataPoint && currentAnswerNeeded.dataPoint === key"
                        cols="12"
                      >
                        <p v-if="currentAnswerNeeded.text" class="white--text">
                          {{ currentAnswerNeeded.text }}
                        </p>
                        <!-- Standard text -->
                        <v-text-field
                          v-if="!currentAnswerNeeded.type"
                          v-model="questions[key]"
                          solo
                          :label="currentAnswerNeeded.prettyName"
                          :mask="currentAnswerNeeded.mask || ''"
                        />
                        <!-- Check boxes -->
                        <v-checkbox
                          v-if="currentAnswerNeeded.type === 'checkbox'"
                          v-model="questions[key]"
                          :label="currentAnswerNeeded.prettyName"
                          dark
                          color="white"
                        />
                        <!-- Address section -->
                        <template v-if="currentAnswerNeeded.type === 'address'">
                          <address-with-search v-model="questions[key]" />
                        </template>
                        <!-- Bank details section -->
                        <template v-if="currentAnswerNeeded.type === 'bankAccountDetails'">
                          <v-card>
                            <v-card-title
                              ><v-icon class="mr-4">account_balance</v-icon>Your Bank Account</v-card-title
                            >

                            <v-card-text class="text--primary">
                              <p>
                                Your earnings are paid by bank transfer each week. Please enter the bank
                                account number and sort code for the bank account you would like to receive
                                payments to. This account must be a UK bank account in your own name.
                              </p>
                              <v-subheader>Please enter your account details</v-subheader>

                              <v-row no-gutters class="justify-center">
                                <v-col cols="12" sm="5">
                                  <v-text-field
                                    v-model="questions[key].bankAccountNumber"
                                    v-mask="accountNumberMask"
                                    label="Account Number"
                                    placeholder="00000000"
                                    hint="Enter your 8-digit account number"
                                    outlined
                                  />
                                </v-col>
                                <v-col cols="12" sm="5" offset-sm="1">
                                  <v-text-field
                                    v-model="questions[key].sortCode"
                                    v-mask="sortCodeMask"
                                    label="Sort Code"
                                    placeholder="00-00-00"
                                    hint="Enter your 6-digit sort code"
                                    outlined
                                    mask="##-##-##"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                          <v-alert type="success" icon="security" class="mt-3">
                            <div class="overline">How we secure your data</div>
                            <p>
                              Your bank account information is transmitted and stored with military-grade
                              encryption. Concorde Online is compliant to the ISO 27001 and PCI-DSS standards,
                              so you can be confident that your information is safe with us.
                            </p>
                          </v-alert>
                        </template>
                        <!-- Confirm button -->
                        <v-btn
                          :loading="saving"
                          class="mt-4"
                          color="primary"
                          @click="
                            currentAnswerNeeded.type === 'finish' ? (dialog = false) : saveAndContinue(key)
                          "
                        >
                          {{ currentAnswerNeeded.type === 'finish' ? 'Finish' : 'Continue' }}
                        </v-btn>
                      </v-col>
                      <v-col>
                        <p class="white--text pointer mt-5 caption" @click="logout()">LOG OUT</p>
                      </v-col>
                    </v-row>
                  </v-slide-x-transition>
                </v-col>
              </v-slide-x-transition>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import addressWithSearch from '~/components/utils/addressWithSearch'
import { mask } from 'vue-the-mask'

import { mapGetters } from 'vuex'

export default {
  directives: { mask },
  components: {
    addressWithSearch
  },
  data: () => ({
    dialog: false,
    step: 0,
    questions: {},
    answersNeeded: [],
    userDataGenerated: false,
    saving: false,
    sortCodeMask: '##-##-##',
    accountNumberMask: '########'
  }),
  computed: {
    ...mapGetters({
      claims: 'user/claims',
      userData: 'user/userData',
      adminID: 'admin/id',
      candidateID: 'candidate/id'
    }),
    isSuperAdmin() {
      const { claims } = this
      return claims && claims.superAdmin
    },
    isAdmin() {
      const { claims } = this
      return claims && claims.admin
    },
    isCompany() {
      const { claims } = this
      return claims && claims.company
    },
    isCandidate() {
      const { claims } = this
      return claims && claims.candidate
    },
    currentAnswerNeeded() {
      const { step, answersNeeded } = this
      const currentStep = step - 1
      if (currentStep === -1) return {}
      return (
        answersNeeded[step - 1] || {
          type: 'finish',
          dataPoint: 'finish',
          text:
            'Thank you for updating your details. Please contact us if anything you entered changes in the future. '
        }
      )
    }
  },
  watch: {
    userData(v) {
      if (v && v.accessEmail) {
        this.$nextTick(() => {
          this.generateAnswersNeeded()
          this.generateQuestions()
        })
      }
    }
  },
  mounted() {
    this.generateAnswersNeeded()
    this.generateQuestions()
  },
  methods: {
    logout() {
      return this.$user.logout()
    },
    async saveAndContinue(key) {
      // save a question and contuinue the flow.
      try {
        const { questions, currentAnswerNeeded } = this
        if (questions && currentAnswerNeeded) {
          console.log({ currentAnswerNeeded, key })
          const { validationFunction } = currentAnswerNeeded
          const { isValid, errorMessage } = validationFunction
            ? validationFunction(questions[key])
            : { isValid: true }
          if (!isValid) {
            return this.$snack.info(errorMessage || 'Please check that you have answered correctly.')
          }
          const { isSuperAdmin, isAdmin, isCompany, isCandidate, claims, adminID, candidateID } = this
          let { userTypeDocID } = claims
          if (!userTypeDocID) {
            userTypeDocID = isSuperAdmin || isAdmin ? adminID : isCandidate ? candidateID : null
          }
          const dbCollection = isSuperAdmin
            ? 'superAdmins'
            : isAdmin
            ? 'admins'
            : isCompany
            ? 'companies'
            : isCandidate
            ? 'candidates'
            : null
          if (!dbCollection) throw new Error('Could not find user type to write to db')
          if (!userTypeDocID) throw new Error('Could not find userID to write to db')
          // just go to the next one now, don't need to wait
          let dataToSave = {}
          this.saving = true
          if (key === 'bankAccountDetails') {
            const bankDetails = await this.validateBankAccountDetails(dbCollection, userTypeDocID)
            dataToSave = { ...bankDetails }
          } else {
            dataToSave = { [key]: questions[key] }
          }
          await this.$db.collection(dbCollection).doc(userTypeDocID).update(dataToSave)
        }
      } catch (e) {
        this.$handleError(e)
      } finally {
        this.saving = false
      }
    },
    async validateBankAccountDetails() {
      const { sortCode, bankAccountNumber } = this.questions.bankAccountDetails
      const { data } = await this.$axios.post('/api/user/verify-bank-account-details', {
        sortCode,
        bankAccountNumber
      })
      const { bankAccountDetails, valid } = data
      if (!valid) {
        throw new Error(`Check your bank account details.\n(${bankAccountDetails.StatusInformation})`)
      }
      return {
        sortCode: bankAccountDetails.CorrectedSortCode,
        bankAccountNumber: bankAccountDetails.CorrectedAccountNumber,
        bankAccountDetails
      }
    },
    reduceQuestions(arr) {
      // reduce an array of 'questions' to remove any that we already have data for.
      const { userData } = this
      console.log(
        'reduceQuestions',
        arr.map((x) => x.dataPoint)
      )
      const newQuestions = arr.reduce((acc, cur) => {
        const { dataPoint } = cur
        if (userData[dataPoint] !== undefined) {
          return acc
        } else {
          return [...acc, cur]
        }
      }, [])
      console.log({ newQuestions: newQuestions.map((x) => x.dataPoint) })
      return newQuestions
    },
    generateAnswersNeeded() {
      const { isSuperAdmin, isCandidate, isCompany, reduceQuestions, userData } = this
      // if the user is a superAdmin, we don't need to bother them
      if (isSuperAdmin) {
        return []
      }
      // check that user data has loaded
      if (!userData || !userData.accessEmail) {
        // if not return an empty array so we don't launch the dialog
        return []
      }
      // build out the questions we need to collect for each user type
      let questions = [
        {
          dataPoint: 'firstName',
          prettyName: 'First Name',
          text: 'What is your first name?',
          validationFunction: (v) => {
            return !v ? { isvalid: false, errorMessage: 'This answer is required' } : { isValid: true }
          }
        },
        {
          dataPoint: 'lastName',
          prettyName: 'Last Name',
          text: 'What is your last name?',
          validationFunction: (v) => {
            return !v ? { isvalid: false, errorMessage: 'This answer is required' } : { isValid: true }
          }
        },
        {
          dataPoint: 'email',
          prettyName: 'Email Address',
          text: 'What is your email address?',
          validationFunction: (v) => {
            return !v ? { isvalid: false, errorMessage: 'This answer is required' } : { isValid: true }
          }
        }
      ]
      if (isCompany) {
        questions = [
          ...questions,
          {
            dataPoint: 'companyName',
            prettyName: 'Company Name',
            text: 'Please enter your company name:',
            validationFunction: (v) => {
              return !v ? { isvalid: false, errorMessage: 'This answer is required' } : { isValid: true }
            }
          }
        ]
      }
      if (isCandidate) {
        // is a candidate
        questions = [
          ...questions,
          {
            dataPoint: 'address',
            prettyName: 'Address',
            text: 'Please enter your address:',
            type: 'address',
            validationFunction: (v) => {
              let validation = { isValid: true }
              if (!v.postcode || !v.address4) {
                validation.isValid = false
                validation.errorMessage = 'Make sure all fields are answered.'
              }
              return validation
            }
          },
          {
            dataPoint: 'bankAccountDetails',
            prettyName: 'Bank account number and sort code',
            text:
              'Your earnings are paid by bank transfer each week. Please enter the bank account number and sort code for the bank account you would like to receive payments to.',
            type: 'bankAccountDetails',
            validationFunction: (v) => {
              return !v ? { isvalid: false, errorMessage: 'This answer is required' } : { isValid: true }
            }
          },
          {
            dataPoint: 'mobileNumber',
            prettyName: 'Mobile Phone Number',
            text: 'Please enter your mobile phone number',
            validationFunction: (v) => {
              return !v ? { isvalid: false, errorMessage: 'This answer is required' } : { isValid: true }
            }
          },
          {
            dataPoint: 'onlyJob',
            prettyName: 'This is my only job',
            type: 'checkbox',
            text:
              'We need to confirm if Concorde is your only employer. Please tick below if this is your only job:'
          },
          {
            dataPoint: 'studentLoan',
            prettyName: 'I have a student loan',
            type: 'checkbox',
            text: 'Please tick if you have a student loan:'
          }
        ]
      }
      // reduce those questions to get rid of any that we already have answers for.
      const reducesQuestions = reduceQuestions(questions)
      this.answersNeeded = [...new Set(reducesQuestions)]
    },
    generateQuestions() {
      const { answersNeeded } = this
      if (answersNeeded) {
        let questions = [...new Set(answersNeeded)]
        // reduce the andswers needed down to an object to v-model to in the text fields
        questions = questions.reduce((acc, cur) => {
          const { dataPoint } = cur
          if (dataPoint === 'address') {
            acc[dataPoint] = {
              firstLine: '',
              secondLine: '',
              city: '',
              county: '',
              postCode: ''
            }
          } else if (dataPoint === 'bankAccountDetails') {
            acc[dataPoint] = {
              sortCode: '',
              bankAccountNumber: ''
            }
          } else {
            acc[dataPoint] = null
          }
          return acc
        }, {})
        // add a finish field at the end to render the finish button
        questions['finish'] = true
        // if there are no answersNeeded, close this dialog
        if (!answersNeeded || answersNeeded.length < 1) {
          // this.dialog = false dont do this
        } else {
          // otherwise, open it.
          this.dialog = true
        }
        // set the questions.
        this.questions = questions
      }
    }
  }
}
</script>

<style lang="css" scoped></style>
