/* Functions to control a 'confirmation' dialog
 ** used for general confirmations like 'Are you sure?'
 */
export default ({ store }, inject) => {
  // Load up the confirm dialog and initiate the 'method' which should be a function that
  // always returns a promise.
  const confirm = (
    method,
    { text = 'Are you sure?', title = 'Confirm', buttons = { confirm: 'Confirm', cancel: 'Cancel' } } = {}
  ) => {
    return new Promise((resolve, reject) => {
      store.commit('dialogs/setConfirmDialog', {
        text,
        title,
        buttons,
        method,
        resolve,
        reject
      })
    })
  }
  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const dialogHelper = () => {
    return { confirm }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('dialog', dialogHelper()) // this.$dialog
}
