const nullState = {
  email: null,
  firstName: '',
  lastName: '',
  mobileNumber: '',
  settings: {},
  id: null,
  requestsByID: [],
  requestsByEmail: []
}

export const state = () => ({
  ...nullState
})

export const mutations = {
  setAuthoriserData(state, data) {
    for (const key in state) {
      if (data && key in data) {
        state[key] = data[key]
      }
    }
  },
  requestsByUserID(state, data) {
    state.requestsByID = data
  },
  requestsByUserEmail(state, data) {
    state.requestsByEmail = data
  }
}

export const actions = {
  // initiate the store by watching some firebase datapoints
  initStore({ dispatch }) {
    dispatch('watchAuthoriserData')
    return dispatch('watchRequests')
  },
  async watchAuthoriserData({ commit, dispatch, rootState }, { userID, mode = 'userID' } = {}) {
    try {
      let queryValue
      const claims = await this.$user.getCustomClaims()
      const collectionName = claims.authoriser ? 'authorisers' : null
      if (!collectionName) {
        throw new Error('watchAuthoriserData: Authoriser type not found from custom claims.')
      }
      let dbRef = this.$db.collection(collectionName)
      if (mode === 'userID') {
        queryValue = await this.$user.getUserID()
        dbRef = dbRef.where('accessUser', 'array-contains', queryValue || false)
      } else if (mode === 'email') {
        queryValue = rootState.user.claims && rootState.user.claims.email
        dbRef = dbRef.where('accessEmail', 'array-contains', queryValue || false)
      } else if (mode === 'phone') {
        queryValue = rootState.user.claims && rootState.user.claims.phone
        dbRef = dbRef.where('accessPhone', 'array-contains', queryValue || false)
      } else if (mode === 'searchEnd') {
        throw new Error('Could not find authoriser doc by userID, email or phone.')
      }
      return this.$watchers.set('authoriserData', dbRef, (snap) => {
        const data = snap.size ? snap.docs.map((x) => x.data())[0] : null
        if (!data) {
          dispatch('stopWatchingAuthoriserData')
          const newMode = mode === 'userID' ? 'email' : mode === 'email' ? 'phone' : 'searchEnd'
          dispatch('watchAuthoriserData', { userID, mode: newMode })
        } else {
          commit('setAuthoriserData', data)
          return commit('user/setUserData', data, { root: true })
        }
      })
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching authoriser data doc
  stopWatchingAuthoriserData() {
    return this.$watchers.stop('authoriserData')
  },
  // watch the candidate's placements
  async watchRequests({ commit }, userID) {
    try {
      const claims = await this.$user.getCustomClaims()
      userID = userID || claims.userID
      const authoriserDoc = await this.$db
        .collection('authorisers')
        .where('accessUser', 'array-contains', userID)
        .get()
      const { id } = authoriserDoc.docs.map((x) => x.data())[0]
      const email = claims && claims.email

      if (id) {
        const dbRefByUserID = this.$db.collection('authoriserRequests').where('authoriserID', '==', id)
        this.$watchers.set('authoriserRequestsByUserID', dbRefByUserID, (snap) => {
          return commit('requestsByUserID', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
      if (email) {
        const dbRefByEmail = this.$db.collection('authoriserRequests').where('email', '==', email)
        this.$watchers.set('authoriserRequestsByEmail', dbRefByEmail, (snap) => {
          return commit('requestsByUserEmail', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching authoriser's requests
  stopWatchingRequests() {
    this.$watchers.stop('authoriserRequestsByUserID')
    this.$watchers.stop('authoriserRequestsByEmail')
  }
}

export const getters = {
  id: (state) => state.id,
  data: (state) => state,
  requests: (state) =>
    [
      ...state.requestsByID,
      ...state.requestsByEmail
      // filter the mapped array to remove any duplicates
      // duplicates can happen if a query has a hit for both accessEmail, accessUser or accessPhone
    ].filter((item, index, self) => {
      return index === self.findIndex((t) => t.id === item.id)
    })
}
