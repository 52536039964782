<template lang="html">
  <v-fade-transition mode="out-in">
    <div
      v-if="!loading"
      key="image"
      :style="{ backgroundImage, 'background-position': position }"
      class="fullScreen"
      :class="{
        'fullScreen--contain': contain,
        'fullScreen--padded': padded,
        miniNav,
        toolbarExtended,
        absoluteCenter
      }"
    >
      <div v-if="loadingBar" class="fullScreen--progress">
        <v-progress-linear class="mt-0" indeterminate color="primary" />
      </div>
    </div>
    <div
      v-if="loading"
      key="loading"
      :style="{
        backgroundImage: backgroundImageLoading,
        'background-position': position
      }"
      class="fullScreen"
      :class="{
        'fullScreen--contain': contain,
        'fullScreen--padded': padded,
        miniNav,
        toolbarExtended,
        absoluteCenter
      }"
    >
      <div v-if="loadingBar" class="fullScreen--progress">
        <v-progress-linear class="mt-0" indeterminate color="primary" />
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    src: {
      type: String,
      default: '/stock/undraw_loading_frh4.svg'
    },
    position: {
      type: String,
      default: ''
    },
    contain: Boolean,
    padded: Boolean,
    loading: Boolean,
    loadingBar: Boolean,
    toolbarExtended: Boolean,
    absoluteCenter: Boolean,
    loadingImage: {
      type: String,
      default: '/stock/undraw_loading_frh4.svg'
    }
  },
  computed: {
    ...mapGetters({
      miniNav: 'utils/miniNav'
    }),
    backgroundImage() {
      return this.src ? `url('${this.src}')` : false
    },
    backgroundImageLoading() {
      return this.loadingImage ? `url('${this.loadingImage}')` : false
    }
  }
}
</script>

<style lang="scss">
.fullScreen {
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &--contain {
    background-size: contain;
  }
  &--padded {
    padding: 150px 0px 100px 0px;
    background-origin: content-box;

    @media screen and (max-width: 959px) {
      padding: 150px 20px 0px 20px;
    }
  }
  &--progress {
    margin-top: -45px;
    padding: 0px 150px;

    @media screen and (max-width: 959px) {
      padding: 0px 20px;
    }
  }
}
.miniNav.fullScreen--padded {
  padding: 150px 0px 100px 80px;
  background-origin: content-box;
  @media screen and (max-width: 959px) {
    padding: 150px 20px 0px 20px;
  }
}
.toolbarExtended.fullScreen--padded {
  padding-top: 200px !important;
  padding-bottom: 50px !important;
  background-origin: content-box;
}
.absoluteCenter.fullScreen--padded {
  padding-top: 200px !important;
  padding-left: 250px !important;
  padding-right: 250px !important;
  background-origin: content-box;
}
</style>
