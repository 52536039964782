<template lang="html">
  <v-container
    fluid
    v-bind="$attrs"
    :style="style"
    class="overflow-y-auto"
    :class="{ 'pa-0': !padded, 'pa-4': padded }"
    v-on="$listeners"
  >
    <slot />
  </v-container>
</template>

<script>
export default {
  props: {
    padded: Boolean,
    navBar: Boolean,
    breadcrumbsBar: Boolean,
    footer: Boolean,
    customHeight: {
      type: [Number],
      default: 0
    }
  },
  data: () => ({
    // navBar height
    navBarDefaultHeight: 64,
    // breadcrumbsBar height
    breadcrumbsBarDefaultHeight: 48,
    // footer height
    footerDefaultHeight: 36
  }),
  computed: {
    style() {
      const {
        noMin,
        navBar,
        customHeight,
        breadcrumbsBar,
        footer,
        navBarDefaultHeight,
        breadcrumbsBarDefaultHeight,
        footerDefaultHeight
      } = this
      const n = navBar ? navBarDefaultHeight : 0
      const b = breadcrumbsBar ? breadcrumbsBarDefaultHeight : 0
      const f = footer ? footerDefaultHeight : 0
      const calc = `calc(100vh - ${n}px - ${b}px - ${f}px - ${customHeight}px)`
      let styleProps = {
        'max-height': calc
      }
      if (noMin) {
        styleProps['min-height'] = calc
      }
      return styleProps
    }
  }
}
</script>

<style lang="css" scoped></style>
