const nullState = {
  id: null,
  email: null,
  firstName: '',
  lastName: '',
  mobileNumber: '',
  nationalInsuranceNumber: '',
  settings: {},
  notices: {},
  placementsByUserID: [],
  placementsByEmail: [],
  placementsByPhoneNumber: [],
  timesheetsByUserID: [],
  timesheetsByEmail: [],
  timesheetsByPhoneNumber: []
}

export const state = () => ({
  ...nullState
})

export const mutations = {
  setCandidateData(state, data) {
    for (const key in state) {
      if (data && key in data) {
        state[key] = data[key]
      }
    }
  },
  placementsByUserID(state, data) {
    state.placementsByUserID = data
  },
  placementsByEmail(state, data) {
    state.placementsByEmail = data
  },
  placementsByPhoneNumber(state, data) {
    state.placementsByPhoneNumber = data
  },
  timesheetsByUserID(state, data) {
    state.timesheetsByUserID = data
  },
  timesheetsByEmail(state, data) {
    state.timesheetsByEmail = data
  },
  timesheetsByPhoneNumber(state, data) {
    state.timesheetsByPhoneNumber = data
  }
}

export const actions = {
  // initiate the store by watching some firebase datapoints
  initStore({ dispatch }) {
    dispatch('watchCandidateData')
    dispatch('watchTimesheets')
    return dispatch('watchPlacements')
  },
  // watch the candidate data doc for this user.
  async watchCandidateData({ commit, dispatch }, userID) {
    try {
      userID = userID || (await this.$user.getUserID())
      if (!userID) {
        dispatch('stopWatchingCandidateData')
      }
      const dbRef = this.$db.collection('candidates').where('accessUser', 'array-contains', userID)
      return this.$watchers.set('candidateData', dbRef, (snap) => {
        const data = snap.size ? snap.docs.map((x) => x.data())[0] : null
        commit('setCandidateData', data)
        return commit('user/setUserData', data, { root: true })
      })
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching candidate data doc
  stopWatchingCandidateData() {
    return this.$watchers.stop('candidateData')
  },
  // watch the candidate's placements
  async watchPlacements({ commit }, userID) {
    try {
      const claims = await this.$user.getCustomClaims()
      userID = userID || claims.userID
      const email = claims && claims.email
      const phoneNumber = claims && claims.phoneNumber
      const isLegacyTimestamp = this.$momentHelpers.subtractFromDate(new Date().toISOString(), 1, 'year')

      if (userID) {
        const dbRefByUserID = this.$db
          .collection('placements')
          .where('startDate', '>', isLegacyTimestamp)
          .where('accessUser', 'array-contains', userID)
          .orderBy('startDate', 'desc')
        this.$watchers.set('candidatePlacementsByUserID', dbRefByUserID, (snap) => {
          return commit('placementsByUserID', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
      if (email) {
        const dbRefByEmail = this.$db
          .collection('placements')
          .where('startDate', '>', isLegacyTimestamp)
          .where('accessEmail', 'array-contains', email)
          .orderBy('startDate', 'desc')
        this.$watchers.set('candidatePlacementsByEmail', dbRefByEmail, (snap) => {
          return commit('placementsByEmail', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
      if (phoneNumber) {
        const dbRefByPhoneNumber = this.$db
          .collection('placements')
          .where('startDate', '>', isLegacyTimestamp)
          .where('accessPhone', 'array-contains', phoneNumber)
          .orderBy('startDate', 'desc')
        this.$watchers.set('candidatePlacementsByPhoneNumber', dbRefByPhoneNumber, (snap) => {
          return commit('placementsByPhoneNumber', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching candidate's placements
  stopWatchingPlacements() {
    this.$watchers.stop('candidatePlacementsByUserID')
    this.$watchers.stop('candidatePlacementsByEmail')
    this.$watchers.stop('candidatePlacementsByPhone')
  },
  // watch the candidates timesheets
  async watchTimesheets({ commit }, userID) {
    try {
      const claims = await this.$user.getCustomClaims()
      userID = userID || claims.userID
      const email = claims && claims.email
      const phoneNumber = claims && claims.phoneNumber
      if (userID) {
        const dbRefByUserID = this.$db
          .collection('timesheets')
          .where('accessUser', 'array-contains', userID)
          .orderBy('startDate', 'desc')
        this.$watchers.set('candidateTimesheetsByUserID', dbRefByUserID, (snap) => {
          return commit('timesheetsByUserID', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
      if (email) {
        const dbRefByEmail = this.$db
          .collection('timesheets')
          .where('accessEmail', 'array-contains', email)
          .orderBy('startDate', 'desc')
        this.$watchers.set('candidateTimesheetsByEmail', dbRefByEmail, (snap) => {
          return commit('timesheetsByEmail', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
      if (phoneNumber) {
        const dbRefByPhoneNumber = this.$db
          .collection('timesheets')
          .where('accessPhone', 'array-contains', phoneNumber)
          .orderBy('startDate', 'desc')
        this.$watchers.set('candidateTimesheetsByPhone', dbRefByPhoneNumber, (snap) => {
          return commit('timesheetsByPhoneNumber', snap.size ? snap.docs.map((x) => x.data()) : [])
        })
      }
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching candidate time sheets.
  stopWatchingTimesheets() {
    this.$watchers.stop('candidateTimesheetsByUserID')
    this.$watchers.stop('candidateTimesheetsByEmail')
    this.$watchers.stop('candidateTimesheetsByPhone')
  }
}

export const getters = {
  id: (state) => state.id,
  candidate: (state) => ({ ...state }),
  settings: (state) => state.settings,
  notices: (state) => state.notices,
  fullName: (state) => `${state.firstName} ${state.lastName}`,
  placements: (state) =>
    [
      ...state.placementsByUserID,
      ...state.placementsByEmail,
      ...state.placementsByPhoneNumber
      // filter the mapped array to remove any duplicates
      // duplicates can happen if a query has a hit for both accessEmail, accessUser or accessPhone
    ].filter((item, index, self) => {
      return index === self.findIndex((t) => t.id === item.id)
    }),
  timesheets: (state) =>
    [
      ...state.timesheetsByUserID,
      ...state.timesheetsByEmail,
      ...state.timesheetsByPhoneNumber
      // filter the mapped array to remove any duplicates
      // duplicates can happen if a query has a hit for both accessEmail, accessUser or accessPhone
    ].filter((item, index, self) => {
      return index === self.findIndex((t) => t.id === item.id)
    })
}
