// Apollo error handler
export default (error, context) => {
  const { $sentry } = context
  console.error(error)
  console.log('gqlError: ', error.graphQLErrors)
  if ($sentry) {
    // Log error with additional info
    $sentry.configureScope((scope) => {
      scope.setExtra('errorsource', 'GraphQL')
      scope.setTag('hygiea_error_source', 'graphql')
      if (error.gqlError) {
        scope.setExtra('gqlErrorPath', error.gqlError.path)
        scope.setExtra('gqlErrorLocations', error.gqlError.locations)
        scope.setExtra('gqlErrorExtensions', error.gqlError.extenstions)
      }
    })
    $sentry.captureException(error)
  }
  // context.error({ statusCode: 304, message: 'Server error' })
}
