import { render, staticRenderFns } from "./ScrollContainer.vue?vue&type=template&id=57aa0639&scoped=true&lang=html&"
import script from "./ScrollContainer.vue?vue&type=script&lang=js&"
export * from "./ScrollContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57aa0639",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/@nuxtjs/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
