const appBaseURL = process.env.BASE_URL || 'http://localhost'
console.log('appBaseURL: ', appBaseURL)

let graphQlPath = '/graphql'
if (!process.env.BASE_URL) {
  // If no BASE_URL is present we're probably in dev env...
  graphQlPath = ':8080/graphql'
  console.log(`Using graphql path: ${graphQlPath}`)
}

export default () => {
  return {
    httpEndpoint: `${appBaseURL}${graphQlPath}`,

    httpLinkOptions: {
      // credentials: 'include',
      fetchOptions: {
        mode: 'cors' // Cors Needed for external Cross origins, need to allow headers from server
      }
    },
    // Sets the authentication type for any authorized request.
    authenticationType: 'Bearer',

    // Token name for the cookie which will be set in case of authentication
    tokenName: 'apollo-token',

    // Cookie parameters used to store authentication token
    cookieAttributes: {
      // /**
      //  * Define when the cookie will be removed. Value can be a Number
      //  * which will be interpreted as days from time of creation or a
      //  * Date instance. If omitted, the cookie becomes a session cookie.
      //  */
      // // expires: 7,

      // /**
      //  * Define the path where the cookie is available. Defaults to '/'
      //  */
      // path: '/',

      // /**
      //  * Define the domain where the cookie is available. Defaults to
      //  * the domain of the page where the cookie was created.
      //  */
      // domain: 'localhost',

      /**
       * A Boolean indicating if the cookie transmission requires a
       * secure protocol (https). Defaults to false.
       */
      secure: !process.env.BASE_URL
    }
  }
}
