<template lang="html">
  <v-navigation-drawer
    v-model="drawer"
    :dark="!isMd"
    app
    fixed
    width="300"
    mobile-breakpoint="960"
    :mini-variant="mini"
    disable-resize-watcher
    clipped
    :class="isMd ? 'white' : 'accent'"
  >
    <v-row v-if="!isMd" no-gutters class="mb-4">
      <v-col cols="4" class="text-left pt-4 pl-4">
        <img src="/concorde-logo-new-white.png" alt="" />
      </v-col>
      <v-col cols="2" offset-xs6 class="text-right">
        <v-btn icon text color="white" @click="drawer = !drawer">
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-list v-if="miniable" class="py-0">
      <v-list-item @click.stop="mini = !mini">
        <v-list-item-action>
          <v-btn icon>
            <v-icon class="crisp-nav-mini-icon" :class="{ rotated: mini }">
              chevron_left
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider v-if="miniable" class="mb-4" />

    <slot />
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    miniable: Boolean
  },
  data: () => ({
    isHydrated: false
  }),
  computed: {
    isMd() {
      // boolean to see if screensize is md or larger must be done after hydration in ssr
      return this.isHydrated ? this.$vuetify.breakpoint.mdAndUp : true
    },
    drawer: {
      get() {
        return this.$store.getters['layout/drawer']
      },
      set(v) {
        return this.$store.commit('layout/drawer', v)
      }
    },
    mini: {
      get() {
        return this.$store.getters['layout/miniDrawer']
      },
      set(v) {
        return this.$store.commit('layout/miniDrawer', v)
      }
    }
  },
  mounted() {
    this.isHydrated = true
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.$nextTick(() => {
        this.drawer = true
      })
    }
  }
}
</script>

<style lang="scss">
.crisp-nav-mini-icon {
  transform: rotate(0deg);
  transition-duration: 300ms;

  &.rotated {
    transform: rotate(180deg);
  }
}
</style>
