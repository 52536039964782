<template lang="html">
  <v-app-bar color="accent" app clipped-left dense>
    <v-toolbar-items v-if="!hideMenuIcon">
      <v-btn
        v-if="(isHydrated && !isMd) || (!isHydrated && isMd) || (isHydrated && isMd && !drawer)"
        text
        color="white"
        icon
        class="mx-0"
        @click="drawer = !drawer"
      >
        <v-icon v-if="(isHydrated && !isMd) || (isHydrated && isMd && !drawer)">menu</v-icon>
      </v-btn>
    </v-toolbar-items>
    <img v-if="isHydrated && isMd" src="/concorde-logo-new-white.png" class="crisp-toolbar-logo" height="38px" />
    <h2
      class="white--text"
      :class="{ 'ml-5': !hideMenuIcon || (isHydrated && isMd) }"
    >{{ pageTitle }}</h2>

    <v-row>
      <v-col class="d-flex justify-start text-center">
        <!-- <v-row no-gutters class="shrink align-center">
          <v-col class="mx-4">
            <v-chip
              label
              color="warning"
              nuxt
              to="/admin/placements/queried-timesheets"
              text-color="white"
            >Search</v-chip>
          </v-col>
        </v-row>-->
        <slot name="leftEnd" />
      </v-col>
      <v-col class="d-flex justify-end text-center">
        <v-row v-if="queriedTSAmount" no-gutters class="shrink align-center">
          <v-col class="mx-4">
            <v-chip
              label
              color="warning"
              nuxt
              to="/admin/placements/queried-timesheets"
              text-color="white"
            >Queried timesheets: {{ queriedTSAmount }}</v-chip>
          </v-col>
        </v-row>
        <slot name="rightEnd" />
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    hideMenuIcon: Boolean
  },
  data: () => ({
    isHydrated: false
  }),
  computed: {
    ...mapGetters({
      pageTitle: 'routes/pageTitle',
      queriedTSAmount: 'admin/queriedTSAmount'
    }),
    drawer: {
      get() {
        return this.$store.getters['layout/drawer']
      },
      set(v) {
        return this.$store.commit('layout/drawer', v)
      }
    },
    isMd() {
      // boolean to see if screensize is md or larger must be done after hydration in ssr
      return this.isHydrated ? this.$vuetify.breakpoint.mdAndUp : true
    }
  },
  mounted() {
    this.isHydrated = true
  }
}
</script>

<style lang="css"></style>
