<template lang="html">
  <v-row class="justify-end shrink" no-gutters>
    <v-fade-transition>
      <v-menu
        v-if="authID"
        v-model="menu"
        transition="slide-y-reverse-transition"
        content-class="accountCardMenuTopCorners elevation-8"
        bottom
        nudge-top="0"
        offset-y
        class="accountCardMenu"
      >
        <template v-slot:activator="{ on }">
          <v-card
            flat
            class="accountCard pointer"
            :class="{
              'accountCardBottomCorners accountCardActive elevation-8': menu,
              transparent: avatarOnly,
              'accountCard--min-width': !avatarOnly
            }"
            v-on="on"
          >
            <v-card-text class="pa-1 accountCardText">
              <v-row
                v-if="userData"
                :class="avatarOnly ? 'justify-end' : false"
                class="flex-nowrap"
                no-gutters
              >
                <v-col v-if="!avatarOnly" cols="auto" class="d-flex align-center">
                  <p class="mb-0 mx-3 animated fadeIn" :class="{ 'secondary--text': !menu }">
                    {{ userData.firstName }} {{ userData.lastName }}
                  </p>
                </v-col>

                <v-col cols="auto" class="mr-2 d-flex shrink align-center">
                  <avatarGenerator
                    :first-name="userData.firstName"
                    :last-name="userData.lastName"
                    :size="40"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <v-card class="accountCardMenuTopCorners">
          <v-card-text class="pa-0">
            <v-list dense class="py-0">
              <v-list-item v-for="(item, i) in menuItems" :key="i" @click="doAction(item.action, item.args)">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-fade-transition>
  </v-row>
</template>

<script>
import avatarGenerator from '~/components/user/avatarGenerator'
import { mapGetters } from 'vuex'
export default {
  components: { avatarGenerator },
  props: {
    avatarOnly: Boolean
  },
  data() {
    return {
      menu: false,
      loggingOut: false,
      menuItems: [
        {
          text: 'Settings',
          icon: 'settings',
          action: 'goTo',
          args: '/admin/account/settings'
        },
        { text: 'Log Out', icon: 'exit_to_app', action: 'logOut' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      authID: 'user/authID'
    })
  },
  methods: {
    doAction(action, args) {
      this[action](args)
    },
    goTo(path) {
      this.$router.push(path)
    },
    logOut() {
      return this.$user.logout()
    }
  }
}
</script>

<style lang="scss">
.accountCardBottomCorners {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.accountCard {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  background-color: rgba(255, 255, 255, 0) !important;
  &--min-width {
    min-width: 140px;
  }
}

.accountCardActive {
  background-color: rgba(255, 255, 255, 1) !important;
}

.accountCardMenu .v-menu__content {
  border-radius: 8px;
}

.accountCardText {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  background-color: transparent;
  border: 0px;
}

.accountCardText:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.accountCardTextContainer {
  max-width: 150px;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
}
.accountCardTextContainer p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accountCardMenuTopCorners {
  border-top: 1px solid rgb(224, 224, 224);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
</style>
