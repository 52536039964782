<template lang="html">
  <v-app>
    <v-main :style="{ backgroundColor: $vuetify.theme.themes.light.accent }">
      <v-container class="animated fadeIn">
        <nuxt />
      </v-container>
    </v-main>

    <!-- Snackbars -->
    <snackBars />

    <!-- here's a general confirmation dialog -->
    <confirmDialog />

    <!-- Here's the need help bottom sheet -->
    <needHelpSheet no-side-drawer />
  </v-app>
</template>

<script>
import needHelpSheet from '~/components/layout/needHelpSheet'
import snackBars from '~/components/utils/snackBars'
import confirmDialog from '~/components/utils/confirmDialog'
export default {
  components: {
    confirmDialog,
    needHelpSheet,
    snackBars
  }
}
</script>

<style lang="css"></style>
