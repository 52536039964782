const nullState = {
  id: null,
  email: null,
  firstName: '',
  lastName: '',
  mobileNumber: '',
  companyID: null,
  company: null,
  requests: []
}

export const state = () => ({
  ...nullState
})

export const mutations = {
  setCompanyUserData(state, data) {
    for (const key in state) {
      if (data && key in data) {
        state[key] = data[key]
      }
    }
  },
  userCompany(state, data) {
    state.company = data
  },
  setCompanyAuthoriserRequests(state, data) {
    state.requests = data
  }
}

export const actions = {
  // initiate the store by watching some firebase datapoints
  async initStore({ dispatch }) {
    await dispatch('watchUserCompany')
    await dispatch('watchCompanyUserData')
  },
  // watch the candidate data doc for this user.
  async watchCompanyUserData({ commit, dispatch }, userID) {
    try {
      userID = userID || (await this.$user.getUserID())
      if (!userID) {
        dispatch('stopWatchingCompanyUserData')
      }
      const dbRef = this.$db.collection('companyUsers').where('accessUser', 'array-contains', userID)
      return this.$watchers.set('companyUserData', dbRef, (snap) => {
        const data = snap.size ? snap.docs.map((x) => x.data())[0] : null
        commit('setCompanyUserData', data)
        return commit('user/setUserData', data, { root: true })
      })
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching candidate data doc
  stopWatchingCompanyUserData() {
    return this.$watchers.stop('companyUserData')
  },
  async watchUserCompany({ commit, dispatch }) {
    try {
      const userID = await this.$user.getUserID()

      if (!userID) {
        dispatch('stopWatchingUserCompany')
      }
      const companyIDRef = await this.$db
        .collection('companyUsers')
        .where('accessUser', 'array-contains', userID)
        .get()
      const { companyID } = companyIDRef.docs.map((x) => x.data())[0]
      if (companyID) {
        const dbRef = this.$db.collection('companies').where('id', '==', companyID)
        this.$watchers.set('companyData', dbRef, (snap) => {
          commit('userCompany', snap.docs.map((x) => x.data())[0])
          return dispatch('watchCompanyAuthRequests')
        })
      }
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching companies timesheets
  stopWatchingUserCompany() {
    return this.$watchers.stop('companyData')
  },
  async watchCompanyAuthRequests({ commit, dispatch, state }) {
    try {
      const userID = await this.$user.getUserID()
      const authorisers = state.company && state.company.authoriserArray
      if (!userID) {
        dispatch('stopWatchingCompanyAuthRequests')
      }
      if (authorisers) {
        const authRequestsRef = this.$db.collection('authoriserRequests')
        this.$watchers.set('authRequests', authRequestsRef, (snap) => {
          const allRequests = snap.docs.map((x) => x.data())
          let requests = []
          authorisers.map((authoriser) => {
            allRequests.map((request) => request.authoriserID === authoriser && requests.push(request))
          })
          return commit('setCompanyAuthoriserRequests', requests)
        })
      }
    } catch (e) {
      this.$handleError(e)
    }
  },
  // stop watching companies timesheets
  stopWatchingCompanyAuthRequests() {
    return this.$watchers.stop('authRequests')
  }
}

export const getters = {
  company: (state) => state.company,
  companyID: (state) => state.companyID,
  authorisers: (state) => (state.company && state.company.authoriserArray) || [],
  requests: (state) => state.requests
}
