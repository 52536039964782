export default (context, inject) => {
  // see if a var is a firebase QuerySnapshot
  const isQuerySnapshot = (v) => {
    return (
      v && // var exists
      !Array.isArray(v) && // var is not an array
      v.docs && // var has a docs key
      Array.isArray(v.docs)
    ) // var's docs key is an array
  }
  // get data out of a firebase get() or onSnapshot() result
  const extractDataFrom = (dbResult) => {
    // handle nothing passed.
    if (!dbResult) {
      throw new Error('No dbResult passed to extractDataFrom()')
    }
    // get the data out of the dbResult
    // add the id from the doc first, then the docs data() so that the id will
    // be overwritten if it exists on the doc (preserve data) and also add an
    // _id incase we absolutely need it. All firebase read results should be passed
    // through this function so that we have consistency
    if (isQuerySnapshot(dbResult)) {
      return dbResult.docs.map((x) => ({ id: x.id, ...x.data(), _id: x.id }))
    } else if (Array.isArray(dbResult)) {
      return dbResult.map((x) => ({ id: x.id, ...x.data(), _id: x.id }))
    } else {
      return { id: dbResult.id, ...dbResult.data(), _id: dbResult.id }
    }
  }
  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const dbHelper = () => {
    return { extractDataFrom }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('dbHelpers', dbHelper()) // this.$dbHelpers
}
