import configDev from './config-dev.js'
import configDynamic from './config.js'

// By default, use dynamic config. This is overwritten in dev envs below.
let config = configDynamic
let configEnvMode = ''

// Environment
// All staging or production environments should have NODE_ENV set to 'production'
// Otherwise we are in a development environment (dev == true)
const dev = process.env.NODE_ENV !== 'production' // dev is true if not NODE_ENV is not production
// If the BASE_URL exists in enviroment vars, set it. If not, default to localhost.
const appBaseURL = process.env.BASE_URL || 'http://localhost'

const version = process.env.VERSION || 'DEVELOPMENT-VERSION'
// console.log('Version: ' + version)
const buildNumber = process.env.BITBUCKET_BUILD_NUMBER || 'UNKNOWN-BUILD'
// console.log('Build Number: ' + buildNumber)
const appEngineVersion = process.env.GAE_VERSION

// Load the relevant config depending on the environment.
// NOTE: Production and staging environments will get sensitive config such as API keys from environment vars.
//       Development keys are fine to commit for easy development setup so if dev = true we use config from the repo.
if (dev && !process.env.BASE_URL) {
  config = configDev
  configEnvMode = '-Dynamic'
  // console.log(
  //   'Deveopment environment with no BASE_URL configuration detected. Using default development environment configuration from config-dev.js'
  // )
  // console.log('Process env api key: ', process.env.CRISP_FIREBASE_API_KEY)
  // console.log('Config API Key: ', config.fireBaseConfig.apiKey)
  // console.log('Base URL: ', appBaseURL)
} else {
  configEnvMode = '-Dev'
  // console.log('Production environment or BASE_URL environment variable was found. Using dynamic environment config from config.js.')
  // console.log('Process env api key: ', process.env.CRISP_FIREBASE_API_KEY)
  // console.log('Config API Key: ', config.fireBaseConfig.apiKey)
  // console.log('Base URL: ', appBaseURL)
}

// Nuxt config:
export default {
  ssr: true,

  /*
   ** Environment
   */
  env: {
    dev,
    rootURL: dev ? config.localURL : config.liveURL,
    functionsURL: dev ? config.localAPIURL : config.liveURL,
    environmentName: config.environmentName + configEnvMode,
    appBaseURL,
    appEngineApp: process.env.GAE_APPLICATION,
    appEngineVersion,
    buildNumber,
    appEngineInstance: process.env.GAE_INSTANCE,
    appEngineDeployment: process.env.GAE_DEPLOYMENT_ID,
    CRISP_FIREBASE_API_KEY: config.fireBaseConfig.apiKey,
    CRISP_FIREBASE_AUTH_DOMAIN: config.fireBaseConfig.authDomain,
    CRISP_FIREBASE_DATABASE_URL: config.fireBaseConfig.databaseURL,
    CRISP_FIREBASE_PROJECT_ID: config.fireBaseConfig.projectId,
    CRISP_FIREBASE_STORAGE_BUCKET: config.fireBaseConfig.storageBucket,
    CRISP_FIREBASE_MESSAGING_SENDER_ID: config.fireBaseConfig.messagingSenderId,
    LOQATE_API_KEY: config.loqate.key,
    version,
    BASE_URL: process.env.BASE_URL
  },

  /*
   ** Router options
   */
  router: {
    middleware: ['routeHelper', 'authed']
  },

  /*
   ** Firebase
   */
  fireBaseConfig: config.fireBaseConfig,

  /*
   ** Headers of the page
   */
  head: {
    title: config.projectName,
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: config.projectDescription
      },
      { hid: 'theme-color', name: 'theme-color', content: config.theme.themes.light.accent }
    ],
    link: [
      { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
      {
        rel: 'stylesheet',
        href:
          'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Cabin:300,400,500,700|Material+Icons'
      }
    ]
  },

  /*
   ** Customize the progress-bar color
   */
  loading: {
    color: config.loadingColor,
    height: '3px',
    continuous: true
  },

  /*
   ** Customize the layout transition
   */
  layoutTransition: {
    name: 'layout',
    mode: 'out-in'
  },

  /*
   ** Customize the page transition
   */
  pageTransition: {
    name: 'slide-x-transition',
    mode: 'out-in'
  },

  /*
   ** Global CSS
   */
  css: [
    'animate.css'
    // '~/assets/style/app.styl',
    // '~/assets/style/custom.scss'
    // '~/assets/style/vuetify-edits.scss'
  ],

  /*
   ** Plugins to load before mounting the App
   */
  plugins: [
    '~/plugins/utils',
    // '~/plugins/vuetify',
    '~/plugins/snack.js',
    // '~/plugins/axios.js',
    '~/plugins/routes.js',
    '~/plugins/moment.js',
    '~/plugins/dialog.js',
    '~/plugins/dbHelpers.js',
    '~/plugins/placements.js',
    '~/plugins/timesheets.js',
    '~/plugins/history/index.js',
    '~/plugins/handleError.js',
    '~/plugins/validation.js',
    '~/plugins/globalComponents.js',
    // The order of these firebase plugins are important.
    '~/plugins/firebase.js',
    '~/plugins/dbWatchers.js',
    '~/plugins/userAuth.js',
    // end important ordering.
    // non ssr plugins:
    { src: '~/plugins/axios.js', ssr: false },
    { src: '~/plugins/cookies.js', ssr: false },
    { src: '~/plugins/VuexPersistence', ssr: false },
    { src: '~/plugins/smoothResize.js', ssr: false },
    { src: '~/plugins/fireAuthState.js', ssr: false },
    { src: '~/plugins/globalComponentsClient.js', ssr: false } // Client side plugins excluded from SSR
  ],

  /*
   ** Nuxt.js modules
   */
  modules: ['@nuxtjs/axios', '@nuxtjs/apollo'],
  buildModules: ['@nuxtjs/sentry', '@nuxtjs/vuetify'],
  /*
   ** Apollo
   */
  apollo: {
    clientConfigs: {
      default: '~/plugins/crisp-apollo-config.js'
    },
    errorHandler: '~/plugins/apollo-error-handler.js'
  },

  /*
   ** Sentry
   */
  sentry: {
    dsn: 'https://e489310bf16f4671997d04972257349f@sentry.io/1392821', // Enter your project's DSN here
    config: {
      release: buildNumber, // $VERSION is exported by the sentry CLI during build pipeline
      environment: process.env.ENVIRONMENT_NAME ? process.env.ENVIRONMENT_NAME : 'development'
    },
    publishRelease: true,
    disabled: false // disable in dev
    // disableServerSide: true
    // clientIntegrations: {
    //   Vue: { attachProps: false, logErrors: true }
    // }
  },

  /*
   ** Axios Config
   */
  axios: {
    // debug: true, // Enable this to assist with troubleshooting, but ensure to switch it off again as it interferes with errors we pass back from the API service that are 'legitimate'
    baseURL: dev ? config.localAPIURL : config.liveURL,
    credentials: true
  },

  vuetify: {
    treeShake: true,
    theme: config.theme,
    icons: {
      iconfont: 'mdi'
    },
    customVariables: ['./assets/style/theme.scss'],
    customProperties: true
  },

  /*
   ** Build configuration
   */
  // buildDir: 'dist',
  build: {
    transpile: ['vuex-persist'],
    /*
     ** You can extend webpack config here
     */
    // analyze: true,
    // cache: true,
    // hardSource: true,
    // parallel: true,
    babel: {
      plugins: ['@babel/plugin-proposal-optional-chaining']
    }
    // extend(config, ctx) {
    //   // Run ESLint on save
    //   if (ctx.isDev && ctx.isClient) {
    //     config.module.rules.push({
    //       enforce: 'pre',
    //       test: /\.(js|vue)$/,
    //       loader: 'eslint-loader',
    //       exclude: /(node_modules)/,
    //       options: {
    //         quiet: true // TODO: [CRISP-143] Set this to only report errors and not warnings. To be removed when warnings have been cleaned!
    //       }
    //     })
    //   }
    // }
  }
}
