/* Functions to set the snackbars app-wide.
 ** Use like:
 ** this.$snack.info('snack text')
 ** this.$snack.loading('snack text')
 ** this.$snack.error('snack text')
 ** Note, the loading snack needs to be turned off manually by calling this.$snack.loading() again
 */
export default ({ store }, inject) => {
  const info = (text) => {
    text = text || 'No text passed to $snack()'
    store.commit('snack/setSnackText', { text, type: 'info' })
    return store.commit('snack/toggleSnack', { type: 'info', value: true })
  }

  const loading = (text, bool) => {
    text = text || 'Loading...'
    store.commit('snack/setSnackText', { text, type: 'loading' })
    return store.commit('snack/toggleSnack', { type: 'loading', value: bool })
  }

  const error = (text) => {
    text = text || 'Something went wrong, please contact support'
    store.commit('snack/setSnackText', { text, type: 'error' })
    return store.commit('snack/toggleSnack', { type: 'error', value: true })
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const snackHelper = () => {
    return { info, loading, error }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('snack', snackHelper()) // this.$snack
}
