export default async ({ store, redirect, app }) => {
  // get the route from the store
  const route = store.getters['routes/current']
  // get some helpers from other plugins
  const { $routeHelper, $user } = app

  // if there is no user, and it's a non-auth route, return true
  if (!$user.getAuthID() && $routeHelper.isNonAuthedRoute(route)) {
    return true
  }

  // if there's no user and it's a setUpAccountRoute, return true
  if (!$user.getAuthID() && $routeHelper.isSetUpAccountRoute(route)) {
    return true
  }

  // if there's no user and it is an authed route, redirect to log-in
  if (!$user.getAuthID() && !$routeHelper.isNonAuthedRoute(route)) {
    return route.path === '/log-in' || redirect('/log-in')
  }

  // if there is a user and it's a redirectHomeRoute, redirect them to 'home'
  if ($user.getAuthID() && $routeHelper.isRedirectHomeRoute(route)) {
    return $routeHelper.redirectHome({ route })
  }

  // if there is a user, and it's a non-auth route, return true
  if ($user.getAuthID() && $routeHelper.isNonAuthedRoute(route)) {
    return true
  }

  // if there is a user and it's a setUpAccountRoute, return true
  if ($user.getAuthID() && $routeHelper.isSetUpAccountRoute(route)) {
    return true
  }

  // if there is a user and it's an authed route, check if the user has permissions for the route
  // and if they don't, redirect them 'home'
  if ($user.getAuthID() && !$routeHelper.isNonAuthedRoute(route)) {
    return $routeHelper.userHasPermissionsForRoute(route)
      ? true
      : $routeHelper.redirectHome({ route, claimsObject: $user.customClaims })
  }
  return $routeHelper.redirectHome({ route, claimsObject: $user.customClaims })
}
