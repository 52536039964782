<template lang="html">
  <div>
    <fullScreenImg
      contain
      padded
      absolute-center
      toolbar-extended
      position="center center"
      src="/stock/undraw_Taken_if77.svg"
    />
    <v-row class="justify-center mt-0">
      <v-col cols="12" class="text-center">
        <v-card flat class="transparent">
          <v-card-text>
            <v-row class="justify-center">
              <v-col cols="12" class="text-center">
                <h2>Looks like something went wrong...</h2>
                <p v-if="error" class="headline mb-0 mt-3">
                  {{ error && error.statusCode }}:
                  {{ (error && error.message) || 'This page could not be found.' }}.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  layout: 'blank',
  props: {
    error: {
      type: undefined,
      default: null
    }
  },
  mounted: function () {
    if (
      this.error.statusCode === 500 &&
      /^Loading chunk [0-9]+ failed/.test(this.error.message) &&
      window.location.hash !== '#retry'
    ) {
      // the chunk might no longer be available due to a recent redeployment of the page
      // mark the page to don't trigger reload infinitely
      window.location.hash = '#retry'
      window.location.reload(true)
    }
  }
}
</script>

<style lang="css"></style>
