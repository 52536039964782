// some utility plugins that don't really need their own files or prototypes
import cloneDeep from 'lodash.clonedeep'
import startCase from 'lodash.startcase'
import uniqBy from 'lodash.uniqby'
export default (context, inject) => {
  // helper to asyncronously setTimeout for waiting in async functions
  const asyncWait = async (millisecs) => {
    await new Promise((resolve) => setTimeout(resolve, millisecs))
  }

  // helper for doing nothing. Mainly to stop prettier complaining when developing.
  const doNowt = () => true

  // help pad a number
  const padNumber = (number, pad) => {
    number = number.toString()
    pad = pad || 2 // pad amount defaults to 6
    while (number.length < pad) {
      number = '0' + number
    }
    return number
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const utils = () => {
    return { cloneDeep, asyncWait, startCase, unCamel: startCase, uniqBy, doNowt, padNumber }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('utils', utils()) // this.$utils
}
