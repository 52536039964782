<template lang="html">
  <div>
    <!-- Info Snack (default) -->
    <v-snackbar v-model="infoToggle" :timeout="6000" bottom color="success" rounded>
      {{ infoText }}
      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon @click.stop="infoToggle = false">
            cancel
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Loading Snack -->
    <v-snackbar v-model="loadingToggle" :timeout="-1" bottom right>
      <v-row no-gutters :style="{ height: '36px' }">
        <v-col cols="10" class="text-left py-0 d-flex align-center">
          <p class="pa-0 ma-0 d-inline-block ml-2">
            {{ loadingText }}
          </p>
        </v-col>
        <v-col cols="2" class="text-right py-0 d-flex align-center">
          <v-progress-circular indeterminate size="13" width="2" />
        </v-col>
      </v-row>
    </v-snackbar>

    <!-- Error Snack -->
    <v-snackbar v-model="errorToggle" :timeout="6000" top centered color="error">
      {{ errorText }}
      <v-btn small icon dark class="pa-0 ma-0" @click="errorToggle = false">
        <v-icon>cancel</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  computed: {
    infoToggle: {
      get() {
        return this.$store.getters['snack/info'].active
      },
      set(value) {
        this.$store.commit('snack/toggleSnack', { type: 'info', value })
      }
    },
    infoText() {
      return this.$store.getters['snack/info'].text
    },
    loadingToggle: {
      get() {
        return this.$store.getters['snack/loading'].active
      },
      set(value) {
        this.$store.commit('snack/toggleSnack', { type: 'loading', value })
      }
    },
    loadingText() {
      return this.$store.getters['snack/loading'].text
    },
    errorToggle: {
      get() {
        return this.$store.getters['snack/error'].active
      },
      set(value) {
        this.$store.commit('snack/toggleSnack', { type: 'error', value })
      }
    },
    errorText() {
      return this.$store.getters['snack/error'].text
    }
  }
}
</script>

<style lang="css"></style>
