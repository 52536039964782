import cookies from 'browser-cookies'
import config from '../config.js'
const { cookiePrependKey } = config

export default (context, inject) => {
  const set = (data, options) => {
    if (!data || typeof data !== 'object') {
      console.error('Pass an object to cookies.set()')
      return false
    }
    // add a prefix to each item in data
    if (process.browser) {
      for (const key in data) {
        cookies.set(`${cookiePrependKey}${key}`, `${data[key]}`, options)
      }
      return true
    } else {
      console.error('Trying to set cookies server side!')
      return false
    }
  }

  const get = (data) => {
    if (!process.browser) {
      console.error('Trying to get cookies server side!')
      return false
    }
    if (data && typeof data !== 'string') {
      console.error('Pass a single string into cookies.get() to get the result')
    }
    // get all cookies
    const allCookies = cookies.all()
    const appCookies = {}
    for (const key in allCookies) {
      if (key.startsWith(cookiePrependKey) && (!data || key === `${cookiePrependKey}${data}`)) {
        appCookies[`${key.replace(cookiePrependKey, '')}`] = allCookies[key]
      }
    }
    // if user requested a cookie that doesn't exist, console error
    if (data && !appCookies[data]) {
      console.error(`Cookie ${cookiePrependKey}${data} is not set.`)
    }
    return data ? appCookies[data] : appCookies
  }

  const erase = (name) => {
    if (!process.browser) {
      console.error('Trying to get cookies server side!')
      return false
    }
    if (!name || typeof name !== 'string') {
      console.error('Pass a single string into cookies.erase() to erase the cookie')
    }
    return cookies.erase(`${cookiePrependKey}${name}`)
  }

  // ***************************************************************
  // Entrypoints
  // ***************************************************************
  const cookiesHelper = () => {
    return { set, get, erase, browser: cookies }
  }
  // ***************************************************************
  // Inject function(s) into the app
  // ***************************************************************
  inject('cookies', cookiesHelper()) // this.$cookies
}
