import { render, staticRenderFns } from "./company.vue?vue&type=template&id=63fe7248&lang=html&"
import script from "./company.vue?vue&type=script&lang=js&"
export * from "./company.vue?vue&type=script&lang=js&"
import style0 from "./company.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/@nuxtjs/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VApp,VAppBar,VChip,VFooter,VIcon,VListItem,VListItemAction,VListItemTitle,VMain,VNavigationDrawer,VSpacer,VSubheader,VTooltip})
